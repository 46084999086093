import Loadable from "react-loadable";
import { Loader } from "semantic-ui-react";

export default {
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'NotFound' */ "./components/NotFoundPage"),
    loading: Loader,
  }),
};
