import React from "react";
import { connectAutoComplete } from "react-instantsearch/connectors";
import { Input, Search } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import { Configure, Index, InstantSearch } from "react-instantsearch/dom";
import { useSelector } from "react-redux";
import placeholder from "../images/placeholder.png";

const { REACT_APP_ALGOLIA_APP_ID } = process.env;
const { REACT_APP_ALGOLIA_API_KEY } = process.env;

const SearchBox = (props) => {
  const results = {};
  const search = props.currentRefinement || "";

  for (const i in props.hits) {
    const index = props.hits[i];
    const name = index.index;

    if (index.hits && index.hits.length > 0) {
      results[name] = {
        key: name,
        id: name,
        name: name.charAt(0).toUpperCase() + name.slice(1),
        results: index.hits.map((hit) => {
          const object = {};
          object.id = hit.objectID;
          object.key = hit.objectID;
          object.title = hit.name || hit.title;
          object.value = `/${name}/${hit.objectID}`;
          object.image = hit.small_image_url || placeholder;
          return object;
        }),
      };
    }
  }
  return (
    <Search
      category
      style={{ flexGrow: 2, borderWidth: 0 }}
      input={
        <Input
          className={"nav-search-box"}
          fluid
          icon={"search"}
          iconPosition={"left"}
          placeholder={"Search by brand or product"}
        />
      }
      onResultSelect={(e, { result }) => props.history.push(`${result.value}`)}
      onSearchChange={(event) => props.refine(event.currentTarget.value)}
      results={results}
    />
  );
};

const ConnectedSearchBox = withRouter(connectAutoComplete(SearchBox));

const Autocomplete = (props) => {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <InstantSearch
      indexName="brands"
      searchClient={algoliasearch(
        REACT_APP_ALGOLIA_APP_ID,
        REACT_APP_ALGOLIA_API_KEY,
      )}
    >
      <Configure hitsPerPage={4} />
      <Index indexName="brands" />
      <Index indexName="products" />
      <ConnectedSearchBox {...props} />
    </InstantSearch>
  );
};

export default Autocomplete;
