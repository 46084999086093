const url = "/api/v2/advertisements";

// Actions

export const RESET_ADVERTISEMENT_STATE = "RESET_ADVERTISEMENT_STATE";

export const GET_ADVERTISEMENT_LIST = "GET_ADVERTISEMENT_LIST";
export const GET_ADVERTISEMENT_LIST_SUCCESS = "GET_ADVERTISEMENT_LIST_SUCCESS";
export const GET_ADVERTISEMENT_LIST_FAILURE = "GET_ADVERTISEMENT_LIST_FAILURE";

export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const GET_ADVERTISEMENT_SUCCESS = "GET_ADVERTISEMENT_SUCCESS";
export const GET_ADVERTISEMENT_FAILURE = "GET_ADVERTISEMENT_FAILURE";

export const CREATE_ADVERTISEMENT = "CREATE_ADVERTISEMENT";
export const CREATE_ADVERTISEMENT_SUCCESS = "CREATE_ADVERTISEMENT_SUCCESS";
export const CREATE_ADVERTISEMENT_FAILURE = "CREATE_ADVERTISEMENT_FAILURE";

export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT_SUCCESS = "UPDATE_ADVERTISEMENT_SUCCESS";
export const UPDATE_ADVERTISEMENT_FAILURE = "UPDATE_ADVERTISEMENT_FAILURE";

export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT_SUCCESS = "DELETE_ADVERTISEMENT_SUCCESS";
export const DELETE_ADVERTISEMENT_FAILURE = "DELETE_ADVERTISEMENT_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT =
  "GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT";
export const GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_ADVERTISEMENT_FAILURE";

export const GET_ARTWORKS_LIST_FOR_ADVERTISEMENT =
  "GET_ARTWORKS_LIST_FOR_ADVERTISEMENT";
export const GET_ARTWORKS_LIST_FOR_ADVERTISEMENT_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_ADVERTISEMENT_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_ADVERTISEMENT_FAILURE =
  "GET_ARTWORKS_LIST_FOR_ADVERTISEMENT_FAILURE";

export const GET_PIECE_LIST_FOR_ADVERTISEMENT =
  "GET_PIECE_LIST_FOR_ADVERTISEMENT";
export const GET_PIECE_LIST_FOR_ADVERTISEMENT_SUCCESS =
  "GET_PIECE_LIST_FOR_ADVERTISEMENT_SUCCESS";
export const GET_PIECE_LIST_FOR_ADVERTISEMENT_FAILURE =
  "GET_PIECE_LIST_FOR_ADVERTISEMENT_FAILURE";

export const GET_EXHIBITION_LIST_FOR_ADVERTISEMENT =
  "GET_EXHIBITION_LIST_FOR_ADVERTISEMENT";
export const GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_FAILURE =
  "GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_ADVERTISEMENT_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT =
  "GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT";
export const GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_ADVERTISEMENT_FAILURE";

export const TOGGLE_ADVERTISEMENT_LIST_DISPLAY =
  "TOGGLE_ADVERTISEMENT_LIST_DISPLAY";

export const GET_SLPAE_FOR_ADVERTISEMENT = "GET_SLPAE_FOR_ADVERTISEMENT";
export const GET_SLPAE_FOR_ADVERTISEMENT_SUCCESS =
  "GET_SLPAE_FOR_ADVERTISEMENT_SUCCESS";
export const GET_SLPAE_FOR_ADVERTISEMENT_FAILURE =
  "GET_SLPAE_FOR_ADVERTISEMENT_FAILURE";

export const GET_PRICE_CHART_FOR_ADVERTISEMENT =
  "GET_PRICE_CHART_FOR_ADVERTISEMENT";
export const GET_PRICE_CHART_FOR_ADVERTISEMENT_SUCCESS =
  "GET_PRICE_CHART_FOR_ADVERTISEMENT_SUCCESS";
export const GET_PRICE_CHART_FOR_ADVERTISEMENT_FAILURE =
  "GET_PRICE_CHART_FOR_ADVERTISEMENT_FAILURE";

export const GET_SUMMARY_FOR_ADVERTISEMENT = "GET_SUMMARY_FOR_ADVERTISEMENT";
export const GET_SUMMARY_FOR_ADVERTISEMENT_SUCCESS =
  "GET_SUMMARY_FOR_ADVERTISEMENT_SUCCESS";
export const GET_SUMMARY_FOR_ADVERTISEMENT_FAILURE =
  "GET_SUMMARY_FOR_ADVERTISEMENT_FAILURE";

// NETWORKING

export const getAdvertisementList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_ADVERTISEMENT_LIST,
      GET_ADVERTISEMENT_LIST_SUCCESS,
      GET_ADVERTISEMENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAdvertisement = (id) => {
  return {
    types: [
      GET_ADVERTISEMENT,
      GET_ADVERTISEMENT_SUCCESS,
      GET_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAdvertisement = (data) => {
  return {
    types: [
      CREATE_ADVERTISEMENT,
      CREATE_ADVERTISEMENT_SUCCESS,
      CREATE_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAdvertisement = (id, data) => {
  return {
    types: [
      UPDATE_ADVERTISEMENT,
      UPDATE_ADVERTISEMENT_SUCCESS,
      UPDATE_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchAdvertisement = (id, data) => {
  return {
    types: [
      UPDATE_ADVERTISEMENT,
      UPDATE_ADVERTISEMENT_SUCCESS,
      UPDATE_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteAdvertisement = (id) => {
  return {
    types: [
      DELETE_ADVERTISEMENT,
      DELETE_ADVERTISEMENT_SUCCESS,
      DELETE_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getAdvertisementProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_ADVERTISEMENT,
      GET_PIECE_LIST_FOR_ADVERTISEMENT_SUCCESS,
      GET_PIECE_LIST_FOR_ADVERTISEMENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetAdvertisementState = () => {
  return {
    type: RESET_ADVERTISEMENT_STATE,
  };
};

export const toggleAdvertisementListDisplay = (display) => {
  return {
    type: TOGGLE_ADVERTISEMENT_LIST_DISPLAY,
    display,
  };
};
