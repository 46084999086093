class ProductRecommendation {
  constructor(data) {
    this._id = data._id;
    this.title = data.title;
    this.about = data.about;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.hair_colors = data.hair_colors || [];
    this.hair_lengths = data.hair_lengths || [];
    this.hair_loss_levels = data.hair_loss_levels || [];
    this.hair_oil_levels = data.hair_oil_levels || [];
    this.hair_porosity_levels = data.hair_porosity_levels || [];
    this.scalp_moisture_levels = data.scalp_moisture_levels || [];
    this.dandruff_levels = data.dandruff_levels || [];
    this.curl_patterns = data.curl_patterns || [];
    this.hair_thicknesses = data.hair_thicknesses || [];
    this.hair_treatment_levels = data.hair_treatment_levels || [];
    this.hair_densities = data.hair_densities || [];
    this.genders = data.genders || [];

    this.products = data.products;

    this.is_premium = data.is_premium;
  }

  toFormData() {
    const formData = new FormData();
    formData.append("name", this.name);
    return formData;
  }
}

export default ProductRecommendation;
