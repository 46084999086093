import {
  ADD_PRODUCT_TO_RELATED_PRODUCT_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_PDF,
  CREATE_PRODUCT_PDF_FAILURE,
  CREATE_PRODUCT_PDF_SUCCESS,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FROM_RELATED_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  RESET_PRODUCT_STATE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
} from "../actions/product";

import { Mixpanel } from "../../utils/Mixpanel";
import Product from "../../models/product";

const INITIAL_STATE = {
  display: "table",

  isLoadingProductList: true,
  products: [],
  hasMoreProducts: true,

  isLoadingProduct: true,
  isCreatingProduct: false,
  isUpdatingProduct: false,

  isCreatingProductPDF: false,

  product: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PRODUCT_STATE:
    case GET_PRODUCT:
      return { ...INITIAL_STATE };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingProduct: false,
        product: new Product(action.payload.data),
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        isLoadingProduct: false,
        error: action.error.response.data,
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        error: null,
        isCreatingProduct: true,
      };
    case CREATE_PRODUCT_SUCCESS: {
      Mixpanel.track("product_created");
      return {
        ...state,
        isCreatingProduct: false,
        product: action.payload.data,
      };
    }
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        isCreatingProduct: false,
        error: action.error.response.data,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        isUpdatingProduct: true,
      };
    case UPDATE_PRODUCT_SUCCESS: {
      const product = action.payload.data;
      Mixpanel.track("product_updated", {
        category: product.category,
        brand: product.brand && product.brand._id,
        gallery: product.gallery && product.gallery._id,
      });
      return {
        ...state,
        isUpdatingProduct: false,
        product: new Product(action.payload.data),
      };
    }
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        isUpdatingProduct: false,
        error: action.error.response.data,
      };

    case CREATE_PRODUCT_PDF:
      return {
        ...state,
        isCreatingProductPDF: true,
      };
    case CREATE_PRODUCT_PDF_SUCCESS:
    case CREATE_PRODUCT_PDF_FAILURE:
      return {
        ...state,
        isCreatingProductPDF: false,
      };

    case ADD_PRODUCT_TO_RELATED_PRODUCT_SUCCESS:
    case DELETE_PRODUCT_FROM_RELATED_PRODUCT_SUCCESS: {
      return {
        ...state,
        isUpdatingProduct: false,
        product: new Product(action.payload.data),
      };
    }

    default:
      return state;
  }
};
