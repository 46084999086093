import {
  CREATE_ADVERTISEMENT_PARTNER,
  CREATE_ADVERTISEMENT_PARTNER_FAILURE,
  CREATE_ADVERTISEMENT_PARTNER_SUCCESS,
  DELETE_ADVERTISEMENT_PARTNER,
  DELETE_ADVERTISEMENT_PARTNER_FAILURE,
  DELETE_ADVERTISEMENT_PARTNER_SUCCESS,
  GET_ADVERTISEMENT_PARTNER,
  GET_ADVERTISEMENT_PARTNER_FAILURE,
  GET_ADVERTISEMENT_PARTNER_SUCCESS,
  GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER,
  GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS,
  GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE,
  GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER,
  GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS,
  GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE,
  RESET_ADVERTISEMENT_PARTNER_STATE,
  UPDATE_ADVERTISEMENT_PARTNER,
  UPDATE_ADVERTISEMENT_PARTNER_FAILURE,
  UPDATE_ADVERTISEMENT_PARTNER_SUCCESS,
} from "../actions/advertisement_partner";

import { Mixpanel } from "../../utils/Mixpanel";
import AdvertisementPartner from "../../models/advertisement_partner";

const INITIAL_STATE = {
  advertisement_partner: null,

  advertisements: [],
  invoices: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ADVERTISEMENT_PARTNER_STATE:
    case GET_ADVERTISEMENT_PARTNER:
      return { ...INITIAL_STATE };
    case GET_ADVERTISEMENT_PARTNER_SUCCESS:
      return {
        ...state,
        isLoadingAdvertisementPartner: false,
        advertisement_partner: new AdvertisementPartner(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_ADVERTISEMENT_PARTNER_FAILURE:
      return {
        ...state,
        isLoadingAdvertisementPartner: false,
        error: action.error.response.data,
      };

    case GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER:
      return { ...state, isLoadingAdvertisements: true };
    case GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS: {
      return {
        ...state,
        isLoadingAdvertisements: false,
        advertisements: [...state.advertisements, ...action.payload.data],
        hasMoreAdvertisements: action.payload.data.length === 25,
      };
    }
    case GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE:
      return {
        ...state,
        isLoadingAdvertisements: false,
        error: action.error.response.data,
        hasMoreAdvertisements: false,
      };

    case GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER:
      return { ...state, isLoadingInvoices: true };
    case GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS: {
      return {
        ...state,
        isLoadingInvoices: false,
        invoices: [...state.invoices, ...action.payload.data],
        hasMoreInvoices: action.payload.data.length === 25,
      };
    }
    case GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE:
      return {
        ...state,
        isLoadingInvoices: false,
        error: action.error.response.data,
        hasMoreInvoices: false,
      };

    case CREATE_ADVERTISEMENT_PARTNER:
      return {
        ...state,
        isCreatingAdvertisementPartner: true,
      };

    case CREATE_ADVERTISEMENT_PARTNER_SUCCESS:
      Mixpanel.track("advertisement_partner_created");
      return {
        ...state,
        isCreatingAdvertisementPartner: false,
      };
    case CREATE_ADVERTISEMENT_PARTNER_FAILURE:
      Mixpanel.track("advertisement_partner_created_failure");
      return {
        ...state,
        isCreatingAdvertisementPartner: false,
      };

    case UPDATE_ADVERTISEMENT_PARTNER:
      return {
        ...state,
        isUpdatingAdvertisementPartner: true,
      };

    case UPDATE_ADVERTISEMENT_PARTNER_SUCCESS:
      Mixpanel.track("advertisement_partner_updated");
      return {
        ...state,
        isUpdatingAdvertisementPartner: false,
        advertisement_partner: new AdvertisementPartner(action.payload.data),
      };
    case UPDATE_ADVERTISEMENT_PARTNER_FAILURE:
      Mixpanel.track("advertisement_partner_updated_failure");
      return {
        ...state,
        isUpdatingAdvertisementPartner: false,
      };

    case DELETE_ADVERTISEMENT_PARTNER:
      return {
        ...state,
        isDeletingAdvertisementPartner: true,
      };

    case DELETE_ADVERTISEMENT_PARTNER_SUCCESS:
      Mixpanel.track("advertisement_partner_deleted");
      return {
        ...state,
        isDeletingAdvertisementPartner: false,
      };
    case DELETE_ADVERTISEMENT_PARTNER_FAILURE:
      Mixpanel.track("advertisement_partner_deleted_failure");
      return {
        ...state,
        isDeletingAdvertisementPartner: false,
      };
    default:
      return state;
  }
};
