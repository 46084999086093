import React from "react";
import ReactDOM from "react-dom";
import { initScripts } from "./utils";
import App from "./containers/App";

import "react-sliding-pane/dist/react-sliding-pane.css";
import "semantic-ui-css/semantic.min.css";
import { store } from "./redux/store";
import "./index.scss";

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
initScripts();

const routes = require("./routes/index").default(store);

ReactDOM.render(
  <App store={store} routes={routes} />,
  document.getElementById("root"),
);
