class Permission {
  constructor(data) {
    this.create_post = data.create_post;
    this.create_comment = data.create_comment;
    this.create_brand = data.create_brand;
    this.create_product = data.create_product;
  }
}

export default Permission;
