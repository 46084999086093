const url = "/api/v1/article_sections";

// Actions
export const RESET_ARTICLE_SECTION_STATE = "RESET_ARTICLE_SECTION_STATE";

export const GET_ARTICLE_SECTION_LIST = "GET_ARTICLE_SECTION_LIST";
export const GET_ARTICLE_SECTION_LIST_SUCCESS =
  "GET_ARTICLE_SECTION_LIST_SUCCESS";
export const GET_ARTICLE_SECTION_LIST_FAILURE =
  "GET_ARTICLE_SECTION_LIST_FAILURE";

export const GET_ARTICLE_SECTION = "GET_ARTICLE_SECTION";
export const GET_ARTICLE_SECTION_SUCCESS = "GET_ARTICLE_SECTION_SUCCESS";
export const GET_ARTICLE_SECTION_FAILURE = "GET_ARTICLE_SECTION_FAILURE";

export const CREATE_ARTICLE_SECTION = "CREATE_ARTICLE_SECTION";
export const CREATE_ARTICLE_SECTION_SUCCESS = "CREATE_ARTICLE_SECTION_SUCCESS";
export const CREATE_ARTICLE_SECTION_FAILURE = "CREATE_ARTICLE_SECTION_FAILURE";

export const UPDATE_ARTICLE_SECTION = "UPDATE_ARTICLE_SECTION";
export const UPDATE_ARTICLE_SECTION_SUCCESS = "UPDATE_ARTICLE_SECTION_SUCCESS";
export const UPDATE_ARTICLE_SECTION_FAILURE = "UPDATE_ARTICLE_SECTION_FAILURE";

export const DELETE_ARTICLE_SECTION = "DELETE_ARTICLE_SECTION";
export const DELETE_ARTICLE_SECTION_SUCCESS = "DELETE_ARTICLE_SECTION_SUCCESS";
export const DELETE_ARTICLE_SECTION_FAILURE = "DELETE_ARTICLE_SECTION_FAILURE";

export const TOGGLE_ARTICLE_SECTION_LIST_DISPLAY =
  "TOGGLE_ARTICLE_SECTION_LIST_DISPLAY";

export const GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION =
  "GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION";
export const GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_SUCCESS =
  "GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_SUCCESS";
export const GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_FAILURE =
  "GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_FAILURE";

export const ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION =
  "ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION";
export const ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS =
  "ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS";
export const ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE =
  "ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE";

export const REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION =
  "REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION";
export const REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS =
  "REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS";
export const REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE =
  "REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE";

// NETWORKING

export const getArticleSectionList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_ARTICLE_SECTION_LIST,
      GET_ARTICLE_SECTION_LIST_SUCCESS,
      GET_ARTICLE_SECTION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArticleSection = (id) => {
  return {
    types: [
      GET_ARTICLE_SECTION,
      GET_ARTICLE_SECTION_SUCCESS,
      GET_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArticleSection = (data) => {
  return {
    types: [
      CREATE_ARTICLE_SECTION,
      CREATE_ARTICLE_SECTION_SUCCESS,
      CREATE_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArticleSection = (id, data) => {
  return {
    types: [
      UPDATE_ARTICLE_SECTION,
      UPDATE_ARTICLE_SECTION_SUCCESS,
      UPDATE_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchArticleSection = (id, data) => {
  return {
    types: [
      UPDATE_ARTICLE_SECTION,
      UPDATE_ARTICLE_SECTION_SUCCESS,
      UPDATE_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteArticleSection = (id) => {
  return {
    types: [
      DELETE_ARTICLE_SECTION,
      DELETE_ARTICLE_SECTION_SUCCESS,
      DELETE_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getArticleCollectionsForArticleSection = (
  id,
  page = 1,
  limit = 25,
) => {
  return {
    types: [
      GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION,
      GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_SUCCESS,
      GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/article_collections`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const addArticleCollectionToArticleSection = (id, data) => {
  return {
    types: [
      ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION,
      ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS,
      ADD_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/article_collections`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteArticleCollectionFromArticleSection = (
  id,
  articleCollectionId,
) => {
  return {
    types: [
      REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION,
      REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_SUCCESS,
      REMOVE_ARTICLE_COLLECTION_TO_ARTICLE_SECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/article_collections/${articleCollectionId}`,
        method: "DELETE",
      },
    },
  };
};

export const resetArticleSectionState = () => {
  return {
    type: RESET_ARTICLE_SECTION_STATE,
  };
};

export const toggleArticleSectionListDisplay = (display) => {
  return {
    type: TOGGLE_ARTICLE_SECTION_LIST_DISPLAY,
    display,
  };
};
