const url = "/api/v1/users";

// Actions

export const RESET_USER_STATE = "RESET_USER_STATE";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_USER =
  "GET_AUCTION_RECORDS_LIST_FOR_USER";
export const GET_AUCTION_RECORDS_LIST_FOR_USER_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_USER_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_USER_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_USER_FAILURE";

export const GET_ARTWORKS_LIST_FOR_USER = "GET_ARTWORKS_LIST_FOR_USER";
export const GET_ARTWORKS_LIST_FOR_USER_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_USER_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_USER_FAILURE =
  "GET_ARTWORKS_LIST_FOR_USER_FAILURE";

export const GET_PIECE_LIST_FOR_USER = "GET_PIECE_LIST_FOR_USER";
export const GET_PIECE_LIST_FOR_USER_SUCCESS =
  "GET_PIECE_LIST_FOR_USER_SUCCESS";
export const GET_PIECE_LIST_FOR_USER_FAILURE =
  "GET_PIECE_LIST_FOR_USER_FAILURE";

export const GET_EXHIBITION_LIST_FOR_USER = "GET_EXHIBITION_LIST_FOR_USER";
export const GET_EXHIBITION_LIST_FOR_USER_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_USER_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_USER_FAILURE =
  "GET_EXHIBITION_LIST_FOR_USER_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_USER_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_USER =
  "GET_SELL_THROUGH_RATES_FOR_USER";
export const GET_SELL_THROUGH_RATES_FOR_USER_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_USER_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_USER_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_USER_FAILURE";

export const TOGGLE_USER_LIST_DISPLAY = "TOGGLE_USER_LIST_DISPLAY";

export const GET_SLPAE_FOR_USER = "GET_SLPAE_FOR_USER";
export const GET_SLPAE_FOR_USER_SUCCESS = "GET_SLPAE_FOR_USER_SUCCESS";
export const GET_SLPAE_FOR_USER_FAILURE = "GET_SLPAE_FOR_USER_FAILURE";

export const GET_HAIR_PROFILE = "GET_HAIR_PROFILE";
export const GET_HAIR_PROFILE_SUCCESS = "GET_HAIR_PROFILE_SUCCESS";
export const GET_HAIR_PROFILE_FAILURE = "GET_HAIR_PROFILE_FAILURE";

export const GET_SUMMARY_FOR_USER = "GET_SUMMARY_FOR_USER";
export const GET_SUMMARY_FOR_USER_SUCCESS = "GET_SUMMARY_FOR_USER_SUCCESS";
export const GET_SUMMARY_FOR_USER_FAILURE = "GET_SUMMARY_FOR_USER_FAILURE";

// NETWORKING

export const getUserList = (page = 1, limit = 250) => {
  return {
    types: [GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getUser = (id) => {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createUser = (data) => {
  return {
    types: [CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateUser = (id, data) => {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchUser = (id, data) => {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteUser = (id) => {
  return {
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getUserProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_USER,
      GET_PIECE_LIST_FOR_USER_SUCCESS,
      GET_PIECE_LIST_FOR_USER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getUserHairProfile = (id) => {
  return {
    types: [
      GET_HAIR_PROFILE,
      GET_HAIR_PROFILE_SUCCESS,
      GET_HAIR_PROFILE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/user_trait_options`,
      },
    },
  };
};

export const resetUserState = () => {
  return {
    type: RESET_USER_STATE,
  };
};

export const toggleUserListDisplay = (display) => {
  return {
    type: TOGGLE_USER_LIST_DISPLAY,
    display,
  };
};
