import {
  ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_SUCCESS,
  CREATE_PRODUCT_RECOMMENDATION,
  CREATE_PRODUCT_RECOMMENDATION_FAILURE,
  CREATE_PRODUCT_RECOMMENDATION_SUCCESS,
  DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS,
  DELETE_PRODUCT_RECOMMENDATION,
  DELETE_PRODUCT_RECOMMENDATION_FAILURE,
  DELETE_PRODUCT_RECOMMENDATION_SUCCESS,
  GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION,
  GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_FAILURE,
  GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_SUCCESS,
  GET_PRODUCT_RECOMMENDATION,
  GET_PRODUCT_RECOMMENDATION_FAILURE,
  GET_PRODUCT_RECOMMENDATION_SUCCESS,
  RESET_PRODUCT_RECOMMENDATION_STATE,
  UPDATE_PRODUCT_RECOMMENDATION,
  UPDATE_PRODUCT_RECOMMENDATION_FAILURE,
  UPDATE_PRODUCT_RECOMMENDATION_SUCCESS,
} from "../actions/product_recommendation";

import { Mixpanel } from "../../utils/Mixpanel";
import ProductRecommendation from "../../models/product_recommendation";

const INITIAL_STATE = {
  productRecommendation: null,

  products: [],

  isLoadingProductRecommendation: true,
  isCreatingProductRecommendation: false,
  isUpdatingProductRecommendation: false,
  isDeletingProductRecommendation: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PRODUCT_RECOMMENDATION_STATE:
    case GET_PRODUCT_RECOMMENDATION:
      return { ...INITIAL_STATE };
    case GET_PRODUCT_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isLoadingProductRecommendation: false,
        productRecommendation: new ProductRecommendation(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_PRODUCT_RECOMMENDATION_FAILURE:
      return {
        ...state,
        isLoadingProductRecommendation: false,
        error: action.error.response.data,
      };

    case GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION:
      return { ...state, isLoadingProducts: true };
    case GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    }
    case GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };

    case CREATE_PRODUCT_RECOMMENDATION:
      return {
        ...state,
        isCreatingProductRecommendation: true,
      };

    case CREATE_PRODUCT_RECOMMENDATION_SUCCESS:
      Mixpanel.track("product_recommendation_created");
      return {
        ...state,
        isCreatingProductRecommendation: false,
      };
    case CREATE_PRODUCT_RECOMMENDATION_FAILURE:
      Mixpanel.track("product_recommendation_created_failure");
      return {
        ...state,
        isCreatingProductRecommendation: false,
      };

    case UPDATE_PRODUCT_RECOMMENDATION:
      return {
        ...state,
        isUpdatingProductRecommendation: true,
      };

    case UPDATE_PRODUCT_RECOMMENDATION_SUCCESS:
      Mixpanel.track("product_recommendation_updated");
      return {
        ...state,
        isUpdatingProductRecommendation: false,
        productRecommendation: new ProductRecommendation(action.payload.data),
      };
    case UPDATE_PRODUCT_RECOMMENDATION_FAILURE:
      Mixpanel.track("product_recommendation_updated_failure");
      return {
        ...state,
        isUpdatingProductRecommendation: false,
      };

    case DELETE_PRODUCT_RECOMMENDATION:
      return {
        ...state,
        isDeletingProductRecommendation: true,
      };

    case DELETE_PRODUCT_RECOMMENDATION_SUCCESS:
      Mixpanel.track("product_recommendation_deleted");
      return {
        ...state,
        isDeletingProductRecommendation: false,
      };
    case DELETE_PRODUCT_RECOMMENDATION_FAILURE:
      Mixpanel.track("product_recommendation_deleted_failure");
      return {
        ...state,
        isDeletingProductRecommendation: false,
      };

    case ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_SUCCESS:
    case DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        isUpdatingProductRecommendation: false,
        productRecommendation: new ProductRecommendation(action.payload.data),
      };
    }

    default:
      return state;
  }
};
