import {
  GET_FEED_LIST,
  GET_FEED_LIST_FAILURE,
  GET_FEED_LIST_SUCCESS,
} from "../actions/feed";

const INITIAL_STATE = {
  isLoadingFeedList: true,
  feeds: [],
  hasMoreFeeds: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FEED_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_FEED_LIST_SUCCESS:
      return {
        ...state,
        isLoadingFeedList: false,
        feeds: [...state.feeds, ...action.payload.data],
        hasMoreFeeds: action.payload.data.length === 25,
      };
    case GET_FEED_LIST_FAILURE:
      return {
        ...state,
        isLoadingFeedList: false,
        error: action.error.response.data,
        hasMoreFeeds: false,
      };
    default:
      return state;
  }
};
