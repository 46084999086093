import {
  CREATE_POST,
  CREATE_POST_FAILURE,
  CREATE_POST_SUCCESS,
  GET_POST,
  GET_POST_FAILURE,
  GET_POST_SUCCESS,
  RESET_POST_STATE,
  UPDATE_POST,
  UPDATE_POST_FAILURE,
  UPDATE_POST_SUCCESS,
  GET_POST_COMMENT_LIST,
  GET_POST_COMMENT_LIST_SUCCESS,
} from "../actions/post";

import { Mixpanel } from "../../utils/Mixpanel";
import Post from "../../models/post";

const INITIAL_STATE = {
  display: "table",

  isLoadingCommentList: true,
  posts: [],
  hasMoreComments: true,

  isLoadingPost: true,
  isCreatingPost: false,
  isUpdatingPost: false,

  post: null,
  comments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_POST_STATE:
    case GET_POST:
      return { ...INITIAL_STATE };
    case GET_POST_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
        post: new Post(action.payload.data),
      };
    case GET_POST_FAILURE:
      return {
        ...state,
        isLoadingPost: false,
        error: action.error.response.data,
      };

    case CREATE_POST:
      return {
        ...state,
        error: null,
        isCreatingPost: true,
      };
    case CREATE_POST_SUCCESS: {
      Mixpanel.track("post_created");
      return {
        ...state,
        isCreatingPost: false,
        post: action.payload.data,
      };
    }
    case CREATE_POST_FAILURE:
      return {
        ...state,
        isCreatingPost: false,
        error: action.error.response.data,
      };

    case UPDATE_POST:
      return {
        ...state,
        isUpdatingPost: true,
      };
    case UPDATE_POST_SUCCESS: {
      const post = action.payload.data;
      Mixpanel.track("post_updated", {
        category: post.category,
        brand: post.brand && post.brand._id,
        gallery: post.gallery && post.gallery._id,
      });
      return {
        ...state,
        isUpdatingPost: false,
        post: new Post(action.payload.data),
      };
    }
    case UPDATE_POST_FAILURE:
      return {
        ...state,
        isUpdatingPost: false,
        error: action.error.response.data,
      };

    case GET_POST_COMMENT_LIST:
      return { ...state, isLoadingComments: true };
    case GET_POST_COMMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoadingComments: false,
        comments: [...state.comments, ...action.payload.data],
        hasMoreComments: action.payload.data.length === 25,
      };
    }
    default:
      return state;
  }
};
