import { Loadable } from "../../utils/components";
import { PASSWORD_RECOVER_PATH as path } from "../../constants/paths";

export default {
  path,
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'PasswordRecover' */ "./components/PasswordRecover"
      ),
  }),
};
