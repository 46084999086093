import {
  GET_INGREDIENT_LIST,
  GET_INGREDIENT_LIST_FAILURE,
  GET_INGREDIENT_LIST_SUCCESS,
} from "../actions/ingredient";

const INITIAL_STATE = {
  isLoadingUserList: true,
  ingredients: [],
  hasMoreUsers: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INGREDIENT_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_INGREDIENT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUserList: false,
        ingredients: [...state.ingredients, ...action.payload.data],
        hasMoreUsers: action.payload.data.length === 25,
      };
    case GET_INGREDIENT_LIST_FAILURE:
      return {
        ...state,
        isLoadingUserList: false,
        error: action.error.response.data,
        hasMoreUsers: false,
      };
    default:
      return state;
  }
};
