import Loadable from "react-loadable";
import { Loader } from "semantic-ui-react";
import { DEDUPLICATE_PRODUCTS_PATH as path } from "../../constants/paths";

export default {
  path,
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: 'NotFound' */ "./components/DeduplicateProductsPage"
      ),
    loading: Loader,
  }),
};
