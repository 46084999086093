const url = "/api/v1/product_recommendations";

// Actions

export const RESET_PRODUCT_RECOMMENDATION_STATE =
  "RESET_PRODUCT_RECOMMENDATION_STATE";

export const GET_PRODUCT_RECOMMENDATION_LIST =
  "GET_PRODUCT_RECOMMENDATION_LIST";
export const GET_PRODUCT_RECOMMENDATION_LIST_SUCCESS =
  "GET_PRODUCT_RECOMMENDATION_LIST_SUCCESS";
export const GET_PRODUCT_RECOMMENDATION_LIST_FAILURE =
  "GET_PRODUCT_RECOMMENDATION_LIST_FAILURE";

export const GET_PRODUCT_RECOMMENDATION = "GET_PRODUCT_RECOMMENDATION";
export const GET_PRODUCT_RECOMMENDATION_SUCCESS =
  "GET_PRODUCT_RECOMMENDATION_SUCCESS";
export const GET_PRODUCT_RECOMMENDATION_FAILURE =
  "GET_PRODUCT_RECOMMENDATION_FAILURE";

export const CREATE_PRODUCT_RECOMMENDATION = "CREATE_PRODUCT_RECOMMENDATION";
export const CREATE_PRODUCT_RECOMMENDATION_SUCCESS =
  "CREATE_PRODUCT_RECOMMENDATION_SUCCESS";
export const CREATE_PRODUCT_RECOMMENDATION_FAILURE =
  "CREATE_PRODUCT_RECOMMENDATION_FAILURE";

export const UPDATE_PRODUCT_RECOMMENDATION = "UPDATE_PRODUCT_RECOMMENDATION";
export const UPDATE_PRODUCT_RECOMMENDATION_SUCCESS =
  "UPDATE_PRODUCT_RECOMMENDATION_SUCCESS";
export const UPDATE_PRODUCT_RECOMMENDATION_FAILURE =
  "UPDATE_PRODUCT_RECOMMENDATION_FAILURE";

export const DELETE_PRODUCT_RECOMMENDATION = "DELETE_PRODUCT_RECOMMENDATION";
export const DELETE_PRODUCT_RECOMMENDATION_SUCCESS =
  "DELETE_PRODUCT_RECOMMENDATION_SUCCESS";
export const DELETE_PRODUCT_RECOMMENDATION_FAILURE =
  "DELETE_PRODUCT_RECOMMENDATION_FAILURE";

export const GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION =
  "GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION";
export const GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_SUCCESS =
  "GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_SUCCESS";
export const GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_FAILURE =
  "GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_FAILURE";

export const ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION =
  "ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION";
export const ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_SUCCESS =
  "ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_SUCCESS";
export const ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_FAILURE =
  "ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_FAILURE";

export const DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION =
  "DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION";
export const DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS =
  "DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS";
export const DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE =
  "DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE";

export const DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION =
  "DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION";
export const DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS =
  "DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS";
export const DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE =
  "DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE";

// NETWORKING

export const getProductRecommendationList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_PRODUCT_RECOMMENDATION_LIST,
      GET_PRODUCT_RECOMMENDATION_LIST_SUCCESS,
      GET_PRODUCT_RECOMMENDATION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getProductRecommendation = (id) => {
  return {
    types: [
      GET_PRODUCT_RECOMMENDATION,
      GET_PRODUCT_RECOMMENDATION_SUCCESS,
      GET_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createProductRecommendation = (data) => {
  return {
    types: [
      CREATE_PRODUCT_RECOMMENDATION,
      CREATE_PRODUCT_RECOMMENDATION_SUCCESS,
      CREATE_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateProductRecommendation = (id, data) => {
  return {
    types: [
      UPDATE_PRODUCT_RECOMMENDATION,
      UPDATE_PRODUCT_RECOMMENDATION_SUCCESS,
      UPDATE_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchProductRecommendation = (id, data) => {
  return {
    types: [
      UPDATE_PRODUCT_RECOMMENDATION,
      UPDATE_PRODUCT_RECOMMENDATION_SUCCESS,
      UPDATE_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteProductRecommendation = (id) => {
  return {
    types: [
      DELETE_PRODUCT_RECOMMENDATION,
      DELETE_PRODUCT_RECOMMENDATION_SUCCESS,
      DELETE_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getProductRecommendationProductsList = (
  id,
  page = 1,
  limit = 25,
) => {
  return {
    types: [
      GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION,
      GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_SUCCESS,
      GET_PRODUCT_LIST_FOR_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const addProductToProductRecommendation = (id, data) => {
  return {
    types: [
      ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION,
      ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_SUCCESS,
      ADD_PRODUCT_TO_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteProductFromProductRecommendation = (id, productId) => {
  return {
    types: [
      DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION,
      DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS,
      DELETE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products/${productId}`,
        method: "DELETE",
      },
    },
  };
};

export const resetProductRecommendationState = () => {
  return {
    type: RESET_PRODUCT_RECOMMENDATION_STATE,
  };
};

export const duplicateProductRecommendation = (id) => {
  return {
    types: [
      DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION,
      DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_SUCCESS,
      DUPLICATE_PRODUCT_FROM_PRODUCT_RECOMMENDATION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/duplicate`,
        method: "POST",
      },
    },
  };
};
