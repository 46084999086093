import {
  GET_INVOICE_LIST,
  GET_INVOICE_LIST_FAILURE,
  GET_INVOICE_LIST_SUCCESS,
} from "../actions/invoice";

const INITIAL_STATE = {
  isLoadingInvoiceList: true,
  invoices: [],
  hasMoreInvoices: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingInvoiceList: false,
        invoices: [...state.invoices, ...action.payload.data],
        hasMoreInvoices: action.payload.data.length === 25,
      };
    case GET_INVOICE_LIST_FAILURE:
      return {
        ...state,
        isLoadingInvoiceList: false,
        error: action.error.response.data,
        hasMoreInvoices: false,
      };
    default:
      return state;
  }
};
