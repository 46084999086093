const url = "/api/v1/authors";

// Actions

export const RESET_AUTHOR_STATE = "RESET_AUTHOR_STATE";

export const GET_AUTHOR_LIST = "GET_AUTHOR_LIST";
export const GET_AUTHOR_LIST_SUCCESS = "GET_AUTHOR_LIST_SUCCESS";
export const GET_AUTHOR_LIST_FAILURE = "GET_AUTHOR_LIST_FAILURE";

export const GET_AUTHOR = "GET_AUTHOR";
export const GET_AUTHOR_SUCCESS = "GET_AUTHOR_SUCCESS";
export const GET_AUTHOR_FAILURE = "GET_AUTHOR_FAILURE";

export const CREATE_AUTHOR = "CREATE_AUTHOR";
export const CREATE_AUTHOR_SUCCESS = "CREATE_AUTHOR_SUCCESS";
export const CREATE_AUTHOR_FAILURE = "CREATE_AUTHOR_FAILURE";

export const UPDATE_AUTHOR = "UPDATE_AUTHOR";
export const UPDATE_AUTHOR_SUCCESS = "UPDATE_AUTHOR_SUCCESS";
export const UPDATE_AUTHOR_FAILURE = "UPDATE_AUTHOR_FAILURE";

export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const DELETE_AUTHOR_SUCCESS = "DELETE_AUTHOR_SUCCESS";
export const DELETE_AUTHOR_FAILURE = "DELETE_AUTHOR_FAILURE";

export const GET_ARTICLE_LIST_FOR_AUTHOR = "GET_ARTICLE_LIST_FOR_AUTHOR";
export const GET_ARTICLE_LIST_FOR_AUTHOR_SUCCESS =
  "GET_ARTICLE_LIST_FOR_AUTHOR_SUCCESS";
export const GET_ARTICLE_LIST_FOR_AUTHOR_FAILURE =
  "GET_ARTICLE_LIST_FOR_AUTHOR_FAILURE";

export const TOGGLE_AUTHOR_LIST_DISPLAY = "TOGGLE_AUTHOR_LIST_DISPLAY";

// NETWORKING

export const getAuthorList = (page = 1, limit = 250) => {
  return {
    types: [GET_AUTHOR_LIST, GET_AUTHOR_LIST_SUCCESS, GET_AUTHOR_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAuthor = (id) => {
  return {
    types: [GET_AUTHOR, GET_AUTHOR_SUCCESS, GET_AUTHOR_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAuthor = (data) => {
  return {
    types: [CREATE_AUTHOR, CREATE_AUTHOR_SUCCESS, CREATE_AUTHOR_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAuthor = (id, data) => {
  return {
    types: [UPDATE_AUTHOR, UPDATE_AUTHOR_SUCCESS, UPDATE_AUTHOR_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const patchAuthor = (id, data) => {
  return {
    types: [UPDATE_AUTHOR, UPDATE_AUTHOR_SUCCESS, UPDATE_AUTHOR_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteAuthor = (id) => {
  return {
    types: [DELETE_AUTHOR, DELETE_AUTHOR_SUCCESS, DELETE_AUTHOR_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getAuthorArticles = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_ARTICLE_LIST_FOR_AUTHOR,
      GET_ARTICLE_LIST_FOR_AUTHOR_SUCCESS,
      GET_ARTICLE_LIST_FOR_AUTHOR_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/articles`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetAuthorState = () => {
  return {
    type: RESET_AUTHOR_STATE,
  };
};

export const toggleAuthorListDisplay = (display) => {
  return {
    type: TOGGLE_AUTHOR_LIST_DISPLAY,
    display,
  };
};
