import {
  CREATE_ADVERTISEMENT,
  CREATE_ADVERTISEMENT_FAILURE,
  CREATE_ADVERTISEMENT_SUCCESS,
  DELETE_ADVERTISEMENT,
  DELETE_ADVERTISEMENT_FAILURE,
  DELETE_ADVERTISEMENT_SUCCESS,
  GET_ADVERTISEMENT,
  GET_ADVERTISEMENT_FAILURE,
  GET_ADVERTISEMENT_SUCCESS,
  GET_EXHIBITION_LIST_FOR_ADVERTISEMENT,
  GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_SUCCESS,
  GET_PIECE_LIST_FOR_ADVERTISEMENT,
  GET_PIECE_LIST_FOR_ADVERTISEMENT_FAILURE,
  GET_PIECE_LIST_FOR_ADVERTISEMENT_SUCCESS,
  RESET_ADVERTISEMENT_STATE,
  UPDATE_ADVERTISEMENT,
  UPDATE_ADVERTISEMENT_FAILURE,
  UPDATE_ADVERTISEMENT_SUCCESS,
} from "../actions/advertisement";

import { Mixpanel } from "../../utils/Mixpanel";
import Advertisement from "../../models/advertisement";

const INITIAL_STATE = {
  advertisement: null,

  products: [],

  isLoadingAdvertisement: true,
  isCreatingAdvertisement: false,
  isUpdatingAdvertisement: false,
  isDeletingAdvertisement: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ADVERTISEMENT_STATE:
    case GET_ADVERTISEMENT:
      return { ...INITIAL_STATE };
    case GET_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        isLoadingAdvertisement: false,
        advertisement: new Advertisement(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_ADVERTISEMENT_FAILURE:
      return {
        ...state,
        isLoadingAdvertisement: false,
        error: action.error.response.data,
      };

    case GET_PIECE_LIST_FOR_ADVERTISEMENT:
      return { ...state, isLoadingProducts: true };
    case GET_PIECE_LIST_FOR_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_ADVERTISEMENT_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };

    case CREATE_ADVERTISEMENT:
      return {
        ...state,
        isCreatingAdvertisement: true,
      };

    case CREATE_ADVERTISEMENT_SUCCESS:
      Mixpanel.track("advertisement_created");
      return {
        ...state,
        isCreatingAdvertisement: false,
      };
    case CREATE_ADVERTISEMENT_FAILURE:
      Mixpanel.track("advertisement_created_failure");
      return {
        ...state,
        isCreatingAdvertisement: false,
      };

    case UPDATE_ADVERTISEMENT:
      return {
        ...state,
        isUpdatingAdvertisement: true,
      };

    case UPDATE_ADVERTISEMENT_SUCCESS:
      Mixpanel.track("advertisement_updated");
      return {
        ...state,
        isUpdatingAdvertisement: false,
        advertisement: new Advertisement(action.payload.data),
      };
    case UPDATE_ADVERTISEMENT_FAILURE:
      Mixpanel.track("advertisement_updated_failure");
      return {
        ...state,
        isUpdatingAdvertisement: false,
      };

    case DELETE_ADVERTISEMENT:
      return {
        ...state,
        isDeletingAdvertisement: true,
      };

    case DELETE_ADVERTISEMENT_SUCCESS:
      Mixpanel.track("advertisement_deleted");
      return {
        ...state,
        isDeletingAdvertisement: false,
      };
    case DELETE_ADVERTISEMENT_FAILURE:
      Mixpanel.track("advertisement_deleted_failure");
      return {
        ...state,
        isDeletingAdvertisement: false,
      };

    case GET_EXHIBITION_LIST_FOR_ADVERTISEMENT:
      return { ...state, isLoadingExhibitions: true };
    case GET_EXHIBITION_LIST_FOR_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        isLoadingExhibitions: false,
        exhibitions: [...state.exhibitions, ...action.payload.data],
      };
    }
    default:
      return state;
  }
};
