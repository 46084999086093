import {
  CREATE_COMPLAINT,
  CREATE_COMPLAINT_FAILURE,
  CREATE_COMPLAINT_SUCCESS,
  DELETE_COMPLAINT,
  DELETE_COMPLAINT_FAILURE,
  DELETE_COMPLAINT_SUCCESS,
  GET_COMPLAINT,
  GET_COMPLAINT_FAILURE,
  GET_COMPLAINT_SUCCESS,
  GET_EXHIBITION_LIST_FOR_COMPLAINT,
  GET_EXHIBITION_LIST_FOR_COMPLAINT_SUCCESS,
  GET_PIECE_LIST_FOR_COMPLAINT,
  GET_PIECE_LIST_FOR_COMPLAINT_FAILURE,
  GET_PIECE_LIST_FOR_COMPLAINT_SUCCESS,
  RESET_COMPLAINT_STATE,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_FAILURE,
  UPDATE_COMPLAINT_SUCCESS,
} from "../actions/complaint";

import { Mixpanel } from "../../utils/Mixpanel";
import Complaint from "../../models/complaint";

const INITIAL_STATE = {
  complaint: null,

  products: [],

  isLoadingComplaint: true,
  isCreatingComplaint: false,
  isUpdatingComplaint: false,
  isDeletingComplaint: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_COMPLAINT_STATE:
    case GET_COMPLAINT:
      return { ...INITIAL_STATE };
    case GET_COMPLAINT_SUCCESS:
      return {
        ...state,
        isLoadingComplaint: false,
        complaint: new Complaint(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_COMPLAINT_FAILURE:
      return {
        ...state,
        isLoadingComplaint: false,
        error: action.error.response.data,
      };

    case GET_PIECE_LIST_FOR_COMPLAINT:
      return { ...state, isLoadingProducts: true };
    case GET_PIECE_LIST_FOR_COMPLAINT_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_COMPLAINT_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };

    case CREATE_COMPLAINT:
      return {
        ...state,
        isCreatingComplaint: true,
      };

    case CREATE_COMPLAINT_SUCCESS:
      Mixpanel.track("complaint_created");
      return {
        ...state,
        isCreatingComplaint: false,
      };
    case CREATE_COMPLAINT_FAILURE:
      Mixpanel.track("complaint_created_failure");
      return {
        ...state,
        isCreatingComplaint: false,
      };

    case UPDATE_COMPLAINT:
      return {
        ...state,
        isUpdatingComplaint: true,
      };

    case UPDATE_COMPLAINT_SUCCESS:
      Mixpanel.track("complaint_updated");
      return {
        ...state,
        isUpdatingComplaint: false,
        complaint: new Complaint(action.payload.data),
      };
    case UPDATE_COMPLAINT_FAILURE:
      Mixpanel.track("complaint_updated_failure");
      return {
        ...state,
        isUpdatingComplaint: false,
      };

    case DELETE_COMPLAINT:
      return {
        ...state,
        isDeletingComplaint: true,
      };

    case DELETE_COMPLAINT_SUCCESS:
      Mixpanel.track("complaint_deleted");
      return {
        ...state,
        isDeletingComplaint: false,
      };
    case DELETE_COMPLAINT_FAILURE:
      Mixpanel.track("complaint_deleted_failure");
      return {
        ...state,
        isDeletingComplaint: false,
      };

    case GET_EXHIBITION_LIST_FOR_COMPLAINT:
      return { ...state, isLoadingExhibitions: true };
    case GET_EXHIBITION_LIST_FOR_COMPLAINT_SUCCESS: {
      return {
        ...state,
        isLoadingExhibitions: false,
        exhibitions: [...state.exhibitions, ...action.payload.data],
      };
    }
    default:
      return state;
  }
};
