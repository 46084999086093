const url = "/api/v1/feed_posts";

// Actions

export const RESET_FEED_POST_STATE = "RESET_FEED_POST_STATE";

export const GET_FEED_POST_LIST = "GET_FEED_POST_LIST";
export const GET_FEED_POST_LIST_SUCCESS = "GET_FEED_POST_LIST_SUCCESS";
export const GET_FEED_POST_LIST_FAILURE = "GET_FEED_POST_LIST_FAILURE";

export const GET_FEED_POST = "GET_FEED_POST";
export const GET_FEED_POST_SUCCESS = "GET_FEED_POST_SUCCESS";
export const GET_FEED_POST_FAILURE = "GET_FEED_POST_FAILURE";

export const CREATE_FEED_POST = "CREATE_FEED_POST";
export const CREATE_FEED_POST_SUCCESS = "CREATE_FEED_POST_SUCCESS";
export const CREATE_FEED_POST_FAILURE = "CREATE_FEED_POST_FAILURE";

export const UPDATE_FEED_POST = "UPDATE_FEED_POST";
export const UPDATE_FEED_POST_SUCCESS = "UPDATE_FEED_POST_SUCCESS";
export const UPDATE_FEED_POST_FAILURE = "UPDATE_FEED_POST_FAILURE";

export const DELETE_FEED_POST = "DELETE_FEED_POST";
export const DELETE_FEED_POST_SUCCESS = "DELETE_FEED_POST_SUCCESS";
export const DELETE_FEED_POST_FAILURE = "DELETE_FEED_POST_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_FEED_POST =
  "GET_AUCTION_RECORDS_LIST_FOR_FEED_POST";
export const GET_AUCTION_RECORDS_LIST_FOR_FEED_POST_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_FEED_POST_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_FEED_POST_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_FEED_POST_FAILURE";

export const GET_ARTWORKS_LIST_FOR_FEED_POST =
  "GET_ARTWORKS_LIST_FOR_FEED_POST";
export const GET_ARTWORKS_LIST_FOR_FEED_POST_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_FEED_POST_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_FEED_POST_FAILURE =
  "GET_ARTWORKS_LIST_FOR_FEED_POST_FAILURE";

export const GET_PIECE_LIST_FOR_FEED_POST = "GET_PIECE_LIST_FOR_FEED_POST";
export const GET_PIECE_LIST_FOR_FEED_POST_SUCCESS =
  "GET_PIECE_LIST_FOR_FEED_POST_SUCCESS";
export const GET_PIECE_LIST_FOR_FEED_POST_FAILURE =
  "GET_PIECE_LIST_FOR_FEED_POST_FAILURE";

export const GET_EXHIBITION_LIST_FOR_FEED_POST =
  "GET_EXHIBITION_LIST_FOR_FEED_POST";
export const GET_EXHIBITION_LIST_FOR_FEED_POST_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_FEED_POST_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_FEED_POST_FAILURE =
  "GET_EXHIBITION_LIST_FOR_FEED_POST_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_FEED_POST_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_FEED_POST =
  "GET_SELL_THROUGH_RATES_FOR_FEED_POST";
export const GET_SELL_THROUGH_RATES_FOR_FEED_POST_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_FEED_POST_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_FEED_POST_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_FEED_POST_FAILURE";

export const TOGGLE_FEED_POST_LIST_DISPLAY = "TOGGLE_FEED_POST_LIST_DISPLAY";

export const GET_SLPAE_FOR_FEED_POST = "GET_SLPAE_FOR_FEED_POST";
export const GET_SLPAE_FOR_FEED_POST_SUCCESS =
  "GET_SLPAE_FOR_FEED_POST_SUCCESS";
export const GET_SLPAE_FOR_FEED_POST_FAILURE =
  "GET_SLPAE_FOR_FEED_POST_FAILURE";

export const GET_PRICE_CHART_FOR_FEED_POST = "GET_PRICE_CHART_FOR_FEED_POST";
export const GET_PRICE_CHART_FOR_FEED_POST_SUCCESS =
  "GET_PRICE_CHART_FOR_FEED_POST_SUCCESS";
export const GET_PRICE_CHART_FOR_FEED_POST_FAILURE =
  "GET_PRICE_CHART_FOR_FEED_POST_FAILURE";

export const GET_SUMMARY_FOR_FEED_POST = "GET_SUMMARY_FOR_FEED_POST";
export const GET_SUMMARY_FOR_FEED_POST_SUCCESS =
  "GET_SUMMARY_FOR_FEED_POST_SUCCESS";
export const GET_SUMMARY_FOR_FEED_POST_FAILURE =
  "GET_SUMMARY_FOR_FEED_POST_FAILURE";

// NETWORKING

export const getFeedPostList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_FEED_POST_LIST,
      GET_FEED_POST_LIST_SUCCESS,
      GET_FEED_POST_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getFeedPost = (id) => {
  return {
    types: [GET_FEED_POST, GET_FEED_POST_SUCCESS, GET_FEED_POST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createFeedPost = (data) => {
  return {
    types: [
      CREATE_FEED_POST,
      CREATE_FEED_POST_SUCCESS,
      CREATE_FEED_POST_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateFeedPost = (id, data) => {
  return {
    types: [
      UPDATE_FEED_POST,
      UPDATE_FEED_POST_SUCCESS,
      UPDATE_FEED_POST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchFeedPost = (id, data) => {
  return {
    types: [
      UPDATE_FEED_POST,
      UPDATE_FEED_POST_SUCCESS,
      UPDATE_FEED_POST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteFeedPost = (id) => {
  return {
    types: [
      DELETE_FEED_POST,
      DELETE_FEED_POST_SUCCESS,
      DELETE_FEED_POST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getFeedPostProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_FEED_POST,
      GET_PIECE_LIST_FOR_FEED_POST_SUCCESS,
      GET_PIECE_LIST_FOR_FEED_POST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetFeedPostState = () => {
  return {
    type: RESET_FEED_POST_STATE,
  };
};

export const toggleFeedPostListDisplay = (display) => {
  return {
    type: TOGGLE_FEED_POST_LIST_DISPLAY,
    display,
  };
};
