class UserTraitOption {
  constructor(data) {
    this._id = data._id || data.id;
    this.name = data.name;
    this.subtitle = data.subtitle;
    this.slug = data.slug;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;
  }
}

export default UserTraitOption;
