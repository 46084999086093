const url = "/api/v1/articles";

// Actions

export const RESET_ARTICLE_STATE = "RESET_ARTICLE_STATE";

export const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";
export const GET_ARTICLE_LIST_SUCCESS = "GET_ARTICLE_LIST_SUCCESS";
export const GET_ARTICLE_LIST_FAILURE = "GET_ARTICLE_LIST_FAILURE";

export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAILURE = "GET_ARTICLE_FAILURE";

export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAILURE = "CREATE_ARTICLE_FAILURE";

export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";

export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_FAILURE = "DELETE_ARTICLE_FAILURE";

export const CREATE_ARTICLE_PDF = "CREATE_ARTICLE_PDF";
export const CREATE_ARTICLE_PDF_SUCCESS = "CREATE_ARTICLE_PDF_SUCCESS";
export const CREATE_ARTICLE_PDF_FAILURE = "CREATE_ARTICLE_PDF_FAILURE";

export const DELETE_ARTICLE_EXHIBITION = "DELETE_ARTICLE_EXHIBITION";
export const DELETE_ARTICLE_EXHIBITION_SUCCESS =
  "DELETE_ARTICLE_EXHIBITION_SUCCESS";
export const DELETE_ARTICLE_EXHIBITION_FAILURE =
  "DELETE_ARTICLE_EXHIBITION_FAILURE";

export const FILTER_ARTICLE_LIST_BY_ARTIST = "FILTER_ARTICLE_LIST_BY_ARTIST";
export const FILTER_ARTICLE_LIST_BY_GALLERY = "FILTER_ARTICLE_LIST_BY_GALLERY";
export const FILTER_ARTICLE_LIST_BY_TEXT = "FILTER_ARTICLE_LIST_BY_TEXT";
export const FILTER_ARTICLE_LIST_BY_TAG = "FILTER_ARTICLE_LIST_BY_TAG";
export const FILTER_ARTICLE_LIST_BY_CATEGORY =
  "FILTER_ARTICLE_LIST_BY_CATEGORY";

export const SORT_ARTICLE_LIST_BY_KEY = "SORT_ARTICLES";
export const TOGGLE_ARTICLE_LIST_DISPLAY = "TOGGLE_ARTICLE_LIST_DISPLAY";
export const SET_ARTICLE_LIST_FILTERS = "SET_ARTICLE_LIST_FILTERS";

// NEW

export const SELECT_ARTICLES_FIELD = "SELECT_ARTICLES_FIELD";
export const UNSELECT_ARTICLES_FIELD = "UNSELECT_ARTICLES_FIELD";

export const ADD_ARTICLES_FILTER = "ADD_ARTICLES_FILTER";
export const REMOVE_ARTICLES_FILTER = "REMOVE_ARTICLES_FILTER";
export const CLEAR_ARTICLES_FILTERS = "CLEAR_ARTICLES_FILTERS";

export const CHANGE_ARTICLES_FILTER_FIELD = "CHANGE_ARTICLES_FILTER_FIELD";
export const CHANGE_ARTICLES_FILTER_OPERATOR =
  "CHANGE_ARTICLES_FILTER_OPERATOR";
export const CHANGE_ARTICLES_FILTER_VALUE = "CHANGE_ARTICLES_FILTER_VALUE";

export const ADD_PRODUCT_TO_ARTICLE = "ADD_PRODUCT_TO_ARTICLE";
export const ADD_PRODUCT_TO_ARTICLE_SUCCESS = "ADD_PRODUCT_TO_ARTICLE_SUCCESS";
export const ADD_PRODUCT_TO_ARTICLE_FAILURE = "ADD_PRODUCT_TO_ARTICLE_FAILURE";

export const DELETE_PRODUCT_FROM_ARTICLE = "DELETE_PRODUCT_FROM_ARTICLE";
export const DELETE_PRODUCT_FROM_ARTICLE_SUCCESS =
  "DELETE_PRODUCT_FROM_ARTICLE_SUCCESS";
export const DELETE_PRODUCT_FROM_ARTICLE_FAILURE =
  "DELETE_PRODUCT_FROM_ARTICLE_FAILURE";

// Networking

export const getArticleList = (
  page = 1,
  limit = 250,
  sort = "created_at",
  direction,
) => {
  return {
    types: [
      GET_ARTICLE_LIST,
      GET_ARTICLE_LIST_SUCCESS,
      GET_ARTICLE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getArticle = (id) => {
  return {
    types: [GET_ARTICLE, GET_ARTICLE_SUCCESS, GET_ARTICLE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArticle = (data) => {
  return {
    types: [CREATE_ARTICLE, CREATE_ARTICLE_SUCCESS, CREATE_ARTICLE_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArticle = (id, data) => {
  return {
    types: [UPDATE_ARTICLE, UPDATE_ARTICLE_SUCCESS, UPDATE_ARTICLE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const createArticlePDF = (id, data) => {
  return {
    types: [
      CREATE_ARTICLE_PDF,
      CREATE_ARTICLE_PDF_SUCCESS,
      CREATE_ARTICLE_PDF_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pdf`,
        method: "POST",
        data,
      },
    },
  };
};

export const deleteArticle = (id) => {
  return {
    types: [DELETE_ARTICLE, DELETE_ARTICLE_SUCCESS, DELETE_ARTICLE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const addProductToArticle = (id, data) => {
  return {
    types: [
      ADD_PRODUCT_TO_ARTICLE,
      ADD_PRODUCT_TO_ARTICLE_SUCCESS,
      ADD_PRODUCT_TO_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,
        method: "PUT",

        data,
      },
    },
  };
};

export const deleteProductFromArticle = (id, productId) => {
  return {
    types: [
      DELETE_PRODUCT_FROM_ARTICLE,
      DELETE_PRODUCT_FROM_ARTICLE_SUCCESS,
      DELETE_PRODUCT_FROM_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products/${productId}`,
        method: "DELETE",
      },
    },
  };
};

export const resetArticle = () => {
  return {
    type: RESET_ARTICLE_STATE,
  };
};
