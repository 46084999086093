import { combineReducers } from "redux";
import firebase from "react-redux-firebase/lib/reducer";
import firestore from "redux-firestore/lib/reducer";

import auth from "./modules/auth";

import author from "./modules/author";
import authors from "./modules/authors";

import user from "./modules/user";
import users from "./modules/users";

import article from "./modules/article";

import article_section from "./modules/article_section";
import article_sections from "./modules/article_sections";

import article_collection from "./modules/article_collection";

import product from "./modules/product";
import products from "./modules/products";

import brand from "./modules/brand";
import brands from "./modules/brands";

import ingredient from "./modules/ingredient";
import ingredients from "./modules/ingredients";

import complaint from "./modules/complaint";
import complaints from "./modules/complaints";

import product_recommendation from "./modules/product_recommendation";
import product_recommendations from "./modules/product_recommendations";

import feed from "./modules/feed";
import feeds from "./modules/feeds";

import feed_post from "./modules/feed_post";
import feed_posts from "./modules/feed_posts";

import advertisement from "./modules/advertisement";
import advertisements from "./modules/advertisements";

import product_article from "./modules/product_article";
import product_articles from "./modules/product_articles";

import post from "./modules/post";
import posts from "./modules/posts";

import advertisement_partner from "./modules/advertisement_partner";
import advertisement_partners from "./modules/advertisement_partners";

import invoice from "./modules/invoice";
import invoices from "./modules/invoices";

import user_trait from "./modules/user_trait";
import user_traits from "./modules/user_traits";

import comment from "./modules/comment";
import comments from "./modules/comments";

import me from "./modules/me";

export default combineReducers({
  firebase,
  firestore,

  auth,

  article,

  product,
  products,

  brand,
  brands,

  ingredient,
  ingredients,

  product_recommendation,
  product_recommendations,

  complaint,
  complaints,

  feed,
  feeds,

  feed_post,
  feed_posts,

  article_section,
  article_sections,

  article_collection,

  user,
  users,

  author,
  authors,

  advertisement,
  advertisements,

  product_article,
  product_articles,

  post,
  posts,

  advertisement_partner,
  advertisement_partners,

  invoice,
  invoices,

  user_trait,
  user_traits,

  comment,
  comments,

  me,
});
