import UserTraitOption from "./user_trait_option";

class UserTrait {
  constructor(data) {
    this._id = data._id || data.id;
    this.name = data.name;
    this.slug = data.slug;

    this.question_title = data.question_title;
    this.question_subtitle = data.question_subtitle;

    this.video_url = data.video_url;
    this.mux_id = data.mux_id;

    this.user_trait_options =
      data.user_trait_options &&
      data.user_trait_options.map((a) => new UserTraitOption(a));
  }
}

export default UserTrait;
