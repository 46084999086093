import Brand from "./brand";

class Product {
  constructor(data) {
    this._id = data._id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.about = data.about;
    this.ingredientText = data.ingredient_text;

    this.website_url = data.website_url;
    this.type = data.type;
    this.brand = data.brand && new Brand(data.brand);
    this.product_type = data.product_type;
    this.barcode_id = data.barcode_id;

    this.hair_colors = data.hair_colors || [];
    this.hair_lengths = data.hair_lengths || [];
    this.hair_loss_levels = data.hair_loss_levels || [];
    this.hair_oil_levels = data.hair_oil_levels || [];
    this.hair_porosity_levels = data.hair_porosity_levels || [];
    this.scalp_moisture_levels = data.scalp_moisture_levels || [];
    this.dandruff_levels = data.dandruff_levels || [];
    this.curl_patterns = data.curl_patterns || [];
    this.hair_thicknesses = data.hair_thicknesses || [];
    this.hair_treatment_levels = data.hair_treatment_levels || [];
    this.hair_densities = data.hair_densities || [];
    this.genders = data.genders || [];
    this.related_products =
      data.related_products && data.related_products.map((a) => new Product(a));
  }

  toFormData() {
    const formData = new FormData();
    formData.append("name", this.name);
    formData.append("about", this.about);
    formData.append("ingredient_text", this.ingredientText);
    formData.append("type", this.type);
    formData.append("brand", this.brand._id);
    formData.append("product_type", this.product_type._id);

    for (const file of this.images) {
      formData.append("images", file, file.name);
    }

    formData.append("hair_colors", this.hair_colors);
    formData.append("hair_lengths", this.hair_lengths);
    formData.append("hair_loss_levels", this.hair_loss_levels);
    formData.append("hair_oil_levels", this.hair_oil_levels);
    formData.append("hair_porosities", this.hair_porosities);
    formData.append("curl_patterns", this.curl_patterns);
    formData.append("hair_thicknesses", this.hair_thicknesses);

    return formData;
  }
}

export default Product;
