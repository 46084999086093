import { fromJS, List, Map } from "immutable";

import {
  CREATE_COMMENT_SUCCESS,
  GET_COMMENT_LIST,
  GET_COMMENT_LIST_FAILURE,
  GET_COMMENT_LIST_SUCCESS,
  TOGGLE_COMMENT_LIST_DISPLAY,
  UPDATE_COMMENT_SUCCESS,
} from "../actions/comment";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingCommentList: true,
  comments: List(),
  hasMoreComments: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMMENT_LIST:
      return state
        .set("isLoadingCommentList", true)
        .set(
          "comments",
          action.payload.request.params.page <= 1
            ? List()
            : state.get("comments"),
        );
    case GET_COMMENT_LIST_SUCCESS:
      return state
        .set("isLoadingCommentList", false)
        .set("hasMoreComments", false)
        .update("comments", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_COMMENT_LIST_FAILURE:
      return state
        .set("isLoadingCommentList", false)
        .set("hasMoreComments", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_COMMENT_SUCCESS:
      return state.update("comments", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_COMMENT_SUCCESS: {
      const index = state
        .get("comments")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(["comments", index], fromJS(action.payload.data));
    }
    case TOGGLE_COMMENT_LIST_DISPLAY: {
      return state.set("display", action.display);
    }
    default:
      return state;
  }
};
