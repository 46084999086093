import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { Dropdown, Image, Loader, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import placeholder from "../../images/profile_placeholder.png";

const AccountMenu = ({ user, handleLogout }) => {
  return (
    <>
      <Menu.Item>
        <Dropdown
          trigger={
            <span>
              <Image avatar src={(user && user.image_url) || placeholder} />
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/account">
              Account Information
            </Dropdown.Item>
            <Dropdown.Item name="LOGOUT" onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
};

AccountMenu.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
