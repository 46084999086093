import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import firebase from "firebase/compat/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import * as config from "../../config";

firebase.initializeApp(config.firebase);

const defaultRRFConfig = {
  userProfile: "users", // root that user profiles are written to
  updateProfileOnLogin: false, // enable/disable updating of profile on login
  sessions: null, // Skip storing of sessions
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
  autoPopulateProfile: true,
};

const App = ({ routes, store }) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={defaultRRFConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <Router>{routes}</Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default App;
