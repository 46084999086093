import {
  GET_FEED,
  GET_FEED_FAILURE,
  GET_FEED_LIST,
  GET_FEED_LIST_FAILURE,
  GET_FEED_LIST_SUCCESS,
  GET_FEED_POST_LIST_FOR_FEED,
  GET_FEED_POST_LIST_FOR_FEED_FAILURE,
  GET_FEED_POST_LIST_FOR_FEED_SUCCESS,
  GET_FEED_SUCCESS,
} from "../actions/feed";

const INITIAL_STATE = {
  isLoadingFeedList: true,
  feeds: [],
  hasMoreFeeds: true,

  isLoadingFeedPosts: true,
  feedPosts: [],
  hasMoreFeedPosts: true,

  error: null,

  isLoadingFeed: true,
  feed: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FEED_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
      break;
    case GET_FEED_LIST_SUCCESS:
      return {
        ...state,
        isLoadingFeedList: false,
        feeds: [...state.feeds, ...action.payload.data],
        hasMoreFeeds: action.payload.data.length === 25,
      };
      break;
    case GET_FEED_LIST_FAILURE:
      return {
        ...state,
        isLoadingFeedList: false,
        error: action.error.response.data,
        hasMoreFeeds: false,
      };

    case GET_FEED:
      return { ...INITIAL_STATE };
    case GET_FEED_SUCCESS:
      return {
        ...state,
        isLoadingFeed: false,
        feed: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionFeedPosts: true,
      };
    case GET_FEED_FAILURE:
      return {
        ...state,
        isLoadingFeed: false,
        error: action.error.response.data,
      };

    case GET_FEED_POST_LIST_FOR_FEED:
      return { ...state };
    case GET_FEED_POST_LIST_FOR_FEED_SUCCESS:
      return {
        ...state,
        isLoadingFeedPosts: false,
        feedPosts: [...state.feedPosts, ...action.payload.data],
        hasMoreFeedPosts: action.payload.data.length === 25,
      };
    case GET_FEED_POST_LIST_FOR_FEED_FAILURE:
      return {
        ...state,
        isLoadingFeedPosts: false,
        error: action.error.response.data,
        hasMoreFeedPosts: false,
      };
    default:
      return state;
  }
};
