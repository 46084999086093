import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Dropdown, Icon, Menu, Responsive, Sidebar } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirebase } from "react-redux-firebase";
import logo from "../../images/logo.png";
import { auth } from "../../utils/firebase";
import {
  BRAND_PATH,
  DASHBOARD_PATH,
  PRODUCT_PATH,
  PRODUCT_RECOMMENDATION_PATH,
  COMPLAINT_PATH,
  USER_PATH,
  INGREDIENT_PATH,
  FEED_PATH,
  FEED_POSTS_PATH,
  ARTICLE_SECTIONS_PATH,
  ARTICLE_COLLECTIONS_PATH,
  DEDUPLICATE_PRODUCTS_PATH,
  AUTHORS_PATH,
  ARTICLES_PATH,
  ADVERTISEMENTS_PATH,
  PRODUCT_ARTICLE_PATH,
  POST_PATH,
  INVOICES_PATH,
  ADVERTISEMENT_PARTNERS_PATH,
  USER_TRAIT_PATH,
} from "../../constants/paths";
import AccountMenu from "./AccountMenu";
import SearchBox from "../../components/AutocompleteSearchBox";
import placeholder from "../../images/profile_placeholder.png";

const userItems = [
  {
    content: "Dashboard",
    to: DASHBOARD_PATH,
    key: DASHBOARD_PATH,
    exact: true,
  },
  { content: "Brands", to: BRAND_PATH, key: BRAND_PATH },
  { content: "Complaints", to: COMPLAINT_PATH, key: COMPLAINT_PATH },
  { content: "Posts", to: POST_PATH, key: POST_PATH },
  { content: "Invoices", to: USER_PATH, key: INVOICES_PATH },
  { content: "Products", to: PRODUCT_PATH, key: PRODUCT_PATH },
  { content: "Users", to: USER_PATH, key: USER_PATH },
];

const NavBarMobile = ({
  children,
  leftItems,
  onPusherClick,
  onToggle,
  rightItems,
  visible,
  searchBox,
  authExists,
  handleLogout,
}) => {
  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        inverted
        onHide={onToggle}
        vertical
        visible={visible}
      >
        {_.map(leftItems, (item) => (
          <Menu.Item as={NavLink} {...item} />
        ))}
      </Sidebar>
      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{ minHeight: "100vh" }}
      >
        <Menu fixed="top" inverted>
          <Menu.Item as={NavLink} to={"/"}>
            <img size={"tiny"} src={logo} style={{ marginTop: "4px" }} />
          </Menu.Item>
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
          {authExists ? (
            <>
              <Menu.Item style={{ flexGrow: 2 }}>{searchBox}</Menu.Item>
              <Menu.Menu position="right">
                <AccountMenu handleLogout={handleLogout} />
              </Menu.Menu>
            </>
          ) : (
            <Menu.Menu position="right">
              {_.map(rightItems, (item) => (
                <Menu.Item as={NavLink} {...item} />
              ))}
            </Menu.Menu>
          )}
        </Menu>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const NavBarDesktop = ({ rightItems, searchBox, authExists, handleLogout }) => (
  <Menu fixed="top" inverted>
    <Menu.Item as={NavLink} to={"/"}>
      <img size={"tiny"} src={logo} style={{ marginTop: "4px" }} />
    </Menu.Item>
    {authExists ? (
      <>
        <Menu.Item style={{ flexGrow: 2 }}>{searchBox}</Menu.Item>
        <Menu.Item as={NavLink} content={"Posts"} to={POST_PATH} />
        <Menu.Item>
          <Dropdown text={"Users"}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={USER_TRAIT_PATH}
                content={"Traits"}
              />
              <Dropdown.Item as={Link} to={USER_PATH} content={"Users"} />
              <Dropdown.Item
                as={Link}
                to={PRODUCT_RECOMMENDATION_PATH}
                content={"Product Recommendations"}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Item>
          <Dropdown text={"Insights"}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ARTICLE_COLLECTIONS_PATH}
                content={"Collections"}
              />
              <Dropdown.Item
                as={Link}
                to={ARTICLE_SECTIONS_PATH}
                content={"Sections"}
              />
              <Dropdown.Item
                as={Link}
                to={ARTICLES_PATH}
                content={"Articles"}
              />
              <Dropdown.Item as={Link} to={AUTHORS_PATH} content={"Authors"} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Item>
          <Dropdown text={"Products"}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={PRODUCT_PATH} content={"Products"} />
              <Dropdown.Item as={Link} to={BRAND_PATH} content={"Brands"} />
              <Dropdown.Item
                as={Link}
                to={INGREDIENT_PATH}
                content={"Ingredients"}
              />
              <Dropdown.Item
                as={Link}
                to={PRODUCT_ARTICLE_PATH}
                content={"Product Articles"}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Item>
          <Dropdown text={"Mane Chats"}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={FEED_PATH} content={"Feeds"} />
              <Dropdown.Item as={Link} to={FEED_POSTS_PATH} content={"Posts"} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Item>
          <Dropdown text={"Admin"}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={COMPLAINT_PATH}
                content={"Complaints"}
              />
              <Dropdown.Item
                as={Link}
                to={DEDUPLICATE_PRODUCTS_PATH}
                content={"Dedupe Products"}
              />
              <Dropdown.Item
                as={Link}
                to={ADVERTISEMENTS_PATH}
                content={"Advertisements"}
              />
              <Dropdown.Item
                as={Link}
                to={ADVERTISEMENT_PARTNERS_PATH}
                content={"Partners"}
              />
              <Dropdown.Item
                as={Link}
                to={INVOICES_PATH}
                content={"Invoices"}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Menu position="right">
          <AccountMenu handleLogout={handleLogout} />
        </Menu.Menu>
      </>
    ) : (
      <Menu.Menu position="right">
        {_.map(rightItems, (item) => (
          <Menu.Item as={NavLink} {...item} />
        ))}
      </Menu.Menu>
    )}
  </Menu>
);

const NavBar = ({ children }) => {
  const authExists = isLoaded(auth) && !isEmpty(auth);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.error(e);
    }
  };

  const login = async () => {
    if (!auth.isLoaded) return;

    try {
      const token = await auth.currentUser.getIdToken(true);
      localStorage.setItem("token", token);
    } catch (e) {
      handleLogout();
    }
  };

  useEffect(() => {
    login();
  }, [auth, login]);

  const searchBox = <SearchBox />;

  const leftItems = userItems;
  return (
    <>
      <Responsive {...Responsive.onlyMobile}>
        <NavBarMobile
          leftItems={leftItems}
          searchBox={searchBox}
          authExists={authExists}
          onToggle={() => setSidebarVisible(!sidebarVisible)}
          visible={sidebarVisible}
          handleLogout={handleLogout}
        >
          {children}
        </NavBarMobile>
      </Responsive>
      <Responsive
        minWidth={Responsive.onlyTablet.minWidth}
        style={{ height: "100%" }}
      >
        <NavBarDesktop
          searchBox={searchBox}
          leftItems={leftItems}
          authExists={authExists}
          handleLogout={handleLogout}
        />
        {children}
      </Responsive>
    </>
  );
};

export default NavBar;
