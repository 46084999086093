import {
  CREATE_BRAND,
  CREATE_BRAND_FAILURE,
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND,
  DELETE_BRAND_FAILURE,
  DELETE_BRAND_SUCCESS,
  GET_BRAND,
  GET_BRAND_FAILURE,
  GET_BRAND_SUCCESS,
  GET_EXHIBITION_LIST_FOR_BRAND,
  GET_EXHIBITION_LIST_FOR_BRAND_SUCCESS,
  GET_PIECE_LIST_FOR_BRAND,
  GET_PIECE_LIST_FOR_BRAND_FAILURE,
  GET_PIECE_LIST_FOR_BRAND_SUCCESS,
  RESET_BRAND_STATE,
  UPDATE_BRAND,
  UPDATE_BRAND_FAILURE,
  UPDATE_BRAND_SUCCESS,
} from "../actions/brand";

import { Mixpanel } from "../../utils/Mixpanel";
import Brand from "../../models/brand";

const INITIAL_STATE = {
  brand: null,

  products: [],

  isLoadingBrand: true,
  isCreatingBrand: false,
  isUpdatingBrand: false,
  isDeletingBrand: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_BRAND_STATE:
    case GET_BRAND:
      return { ...INITIAL_STATE };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        isLoadingBrand: false,
        brand: new Brand(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_BRAND_FAILURE:
      return {
        ...state,
        isLoadingBrand: false,
        error: action.error.response.data,
      };

    case GET_PIECE_LIST_FOR_BRAND:
      return { ...state, isLoadingProducts: true };
    case GET_PIECE_LIST_FOR_BRAND_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    }
    case GET_PIECE_LIST_FOR_BRAND_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };

    case CREATE_BRAND:
      return {
        ...state,
        isCreatingBrand: true,
      };

    case CREATE_BRAND_SUCCESS:
      Mixpanel.track("brand_created");
      return {
        ...state,
        isCreatingBrand: false,
      };
    case CREATE_BRAND_FAILURE:
      Mixpanel.track("brand_created_failure");
      return {
        ...state,
        isCreatingBrand: false,
      };

    case UPDATE_BRAND:
      return {
        ...state,
        isUpdatingBrand: true,
      };

    case UPDATE_BRAND_SUCCESS:
      Mixpanel.track("brand_updated");
      return {
        ...state,
        isUpdatingBrand: false,
        brand: new Brand(action.payload.data),
      };
    case UPDATE_BRAND_FAILURE:
      Mixpanel.track("brand_updated_failure");
      return {
        ...state,
        isUpdatingBrand: false,
      };

    case DELETE_BRAND:
      return {
        ...state,
        isDeletingBrand: true,
      };

    case DELETE_BRAND_SUCCESS:
      Mixpanel.track("brand_deleted");
      return {
        ...state,
        isDeletingBrand: false,
      };
    case DELETE_BRAND_FAILURE:
      Mixpanel.track("brand_deleted_failure");
      return {
        ...state,
        isDeletingBrand: false,
      };

    case GET_EXHIBITION_LIST_FOR_BRAND:
      return { ...state, isLoadingExhibitions: true };
    case GET_EXHIBITION_LIST_FOR_BRAND_SUCCESS: {
      return {
        ...state,
        isLoadingExhibitions: false,
        exhibitions: [...state.exhibitions, ...action.payload.data],
      };
    }
    default:
      return state;
  }
};
