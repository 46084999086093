import {
  GET_ARTICLE_COLLECTION,
  GET_ARTICLE_COLLECTION_FAILURE,
  GET_ARTICLE_COLLECTION_SUCCESS,
  GET_ARTICLES_FOR_ARTICLE_COLLECTION,
  GET_ARTICLES_FOR_ARTICLE_COLLECTION_FAILURE,
  GET_ARTICLES_FOR_ARTICLE_COLLECTION_SUCCESS,
} from "../actions/article_collection";

const INITIAL_STATE = {
  isLoadingArticleList: true,
  articles: [],

  isLoadingArticleCollection: true,
  articleCollection: null,

  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTICLE_COLLECTION:
      return { ...INITIAL_STATE };
    case GET_ARTICLE_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoadingArticleCollection: false,
        articleCollection: action.payload.data,
      };
    case GET_ARTICLE_COLLECTION_FAILURE:
      return {
        ...state,
        isLoadingArticleCollection: false,
        error: action.error.response.data,
      };

    case GET_ARTICLES_FOR_ARTICLE_COLLECTION:
      return { ...state };
    case GET_ARTICLES_FOR_ARTICLE_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoadingArticleCollections: false,
        articles: [...state.articles, ...action.payload.data],
        hasMoreArticleCollections: action.payload.data.length === 25,
      };
    case GET_ARTICLES_FOR_ARTICLE_COLLECTION_FAILURE:
      return {
        ...state,
        isLoadingArticleCollections: false,
        error: action.error.response.data,
        hasMoreArticleCollections: false,
      };
    default:
      return state;
  }
};
