import moment from "moment";
import InvoiceLineItem from "./invoice_line_item";

class Invoice {
  constructor(data) {
    this._id = data._id;
    this.created_at = data.created_at;
    this.line_items = (data.line_items || []).map(
      (item) => new InvoiceLineItem(item),
    );

    this.advertisement_partner = data.advertisement_partner;
    this.total = this.line_items.reduce((a, b) => a + b.total, 0);
  }

  dateString() {
    return moment(this.created_at).format("MMM D, YYYY");
  }

  totalPriceString() {
    const total = this.line_items.reduce(function (sum, lineItem) {
      const updatedSum = sum + lineItem.total;
      return updatedSum;
    }, 0);

    return (total / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
}

export default Invoice;
