import Advertisement from "./advertisement";

class InvoiceLineItem {
  constructor(data) {
    console.log(data);
    this._id = data._id;
    this.advertisement =
      data.advertisement && new Advertisement(data.advertisement);
    this.description = data.description;

    this.quantity = data.quantity;
    this.price = data.price;
    this.total = data.total;
  }
}

export default InvoiceLineItem;
