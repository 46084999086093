import { fromJS, List, Map } from "immutable";

import {
  CREATE_BRAND_SUCCESS,
  GET_BRAND_LIST,
  GET_BRAND_LIST_FAILURE,
  GET_BRAND_LIST_SUCCESS,
  TOGGLE_BRAND_LIST_DISPLAY,
  UPDATE_BRAND_SUCCESS,
} from "../actions/brand";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingBrandList: true,
  brands: List(),
  hasMoreBrands: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BRAND_LIST:
      return state
        .set("isLoadingBrandList", true)
        .set(
          "brands",
          action.payload.request.params.page <= 1
            ? List()
            : state.get("brands"),
        );
    case GET_BRAND_LIST_SUCCESS:
      return state
        .set("isLoadingBrandList", false)
        .set("hasMoreBrands", false)
        .update("brands", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_BRAND_LIST_FAILURE:
      return state
        .set("isLoadingBrandList", false)
        .set("hasMoreBrands", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_BRAND_SUCCESS:
      return state.update("brands", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_BRAND_SUCCESS: {
      const index = state
        .get("brands")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(["brands", index], fromJS(action.payload.data));
    }
    case TOGGLE_BRAND_LIST_DISPLAY: {
      return state.set("display", action.display);
    }
    default:
      return state;
  }
};
