import {
  GET_PRODUCT_ARTICLE_LIST,
  GET_PRODUCT_ARTICLE_LIST_FAILURE,
  GET_PRODUCT_ARTICLE_LIST_SUCCESS,
} from "../actions/product_article";

const INITIAL_STATE = {
  isLoadingProductArticleList: true,
  productArticles: [],
  hasMoreProductArticles: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_ARTICLE_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_PRODUCT_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingProductArticleList: false,
        productArticles: [...state.productArticles, ...action.payload.data],
        hasMoreProductArticles: action.payload.data.length === 25,
      };
    case GET_PRODUCT_ARTICLE_LIST_FAILURE:
      return {
        ...state,
        isLoadingProductArticleList: false,
        error: action.error.response.data,
        hasMoreProductArticles: false,
      };
    default:
      return state;
  }
};
