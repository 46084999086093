const url = "/api/v1/brands";

// Actions

export const RESET_BRAND_STATE = "RESET_BRAND_STATE";

export const GET_BRAND_LIST = "GET_BRAND_LIST";
export const GET_BRAND_LIST_SUCCESS = "GET_BRAND_LIST_SUCCESS";
export const GET_BRAND_LIST_FAILURE = "GET_BRAND_LIST_FAILURE";

export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAILURE = "GET_BRAND_FAILURE";

export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";

export const UPDATE_BRAND = "UPDATE_BRAND";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";

export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_BRAND =
  "GET_AUCTION_RECORDS_LIST_FOR_BRAND";
export const GET_AUCTION_RECORDS_LIST_FOR_BRAND_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_BRAND_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_BRAND_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_BRAND_FAILURE";

export const GET_ARTWORKS_LIST_FOR_BRAND = "GET_ARTWORKS_LIST_FOR_BRAND";
export const GET_ARTWORKS_LIST_FOR_BRAND_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_BRAND_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_BRAND_FAILURE =
  "GET_ARTWORKS_LIST_FOR_BRAND_FAILURE";

export const GET_PIECE_LIST_FOR_BRAND = "GET_PIECE_LIST_FOR_BRAND";
export const GET_PIECE_LIST_FOR_BRAND_SUCCESS =
  "GET_PIECE_LIST_FOR_BRAND_SUCCESS";
export const GET_PIECE_LIST_FOR_BRAND_FAILURE =
  "GET_PIECE_LIST_FOR_BRAND_FAILURE";

export const GET_EXHIBITION_LIST_FOR_BRAND = "GET_EXHIBITION_LIST_FOR_BRAND";
export const GET_EXHIBITION_LIST_FOR_BRAND_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_BRAND_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_BRAND_FAILURE =
  "GET_EXHIBITION_LIST_FOR_BRAND_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_BRAND_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_BRAND =
  "GET_SELL_THROUGH_RATES_FOR_BRAND";
export const GET_SELL_THROUGH_RATES_FOR_BRAND_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_BRAND_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_BRAND_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_BRAND_FAILURE";

export const TOGGLE_BRAND_LIST_DISPLAY = "TOGGLE_BRAND_LIST_DISPLAY";

export const GET_SLPAE_FOR_BRAND = "GET_SLPAE_FOR_BRAND";
export const GET_SLPAE_FOR_BRAND_SUCCESS = "GET_SLPAE_FOR_BRAND_SUCCESS";
export const GET_SLPAE_FOR_BRAND_FAILURE = "GET_SLPAE_FOR_BRAND_FAILURE";

export const GET_PRICE_CHART_FOR_BRAND = "GET_PRICE_CHART_FOR_BRAND";
export const GET_PRICE_CHART_FOR_BRAND_SUCCESS =
  "GET_PRICE_CHART_FOR_BRAND_SUCCESS";
export const GET_PRICE_CHART_FOR_BRAND_FAILURE =
  "GET_PRICE_CHART_FOR_BRAND_FAILURE";

export const GET_SUMMARY_FOR_BRAND = "GET_SUMMARY_FOR_BRAND";
export const GET_SUMMARY_FOR_BRAND_SUCCESS = "GET_SUMMARY_FOR_BRAND_SUCCESS";
export const GET_SUMMARY_FOR_BRAND_FAILURE = "GET_SUMMARY_FOR_BRAND_FAILURE";

// NETWORKING

export const getBrandList = (page = 1, limit = 250) => {
  return {
    types: [GET_BRAND_LIST, GET_BRAND_LIST_SUCCESS, GET_BRAND_LIST_FAILURE],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getBrand = (id) => {
  return {
    types: [GET_BRAND, GET_BRAND_SUCCESS, GET_BRAND_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createBrand = (data) => {
  return {
    types: [CREATE_BRAND, CREATE_BRAND_SUCCESS, CREATE_BRAND_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateBrand = (id, data) => {
  return {
    types: [UPDATE_BRAND, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchBrand = (id, data) => {
  return {
    types: [UPDATE_BRAND, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteBrand = (id) => {
  return {
    types: [DELETE_BRAND, DELETE_BRAND_SUCCESS, DELETE_BRAND_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getBrandProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_BRAND,
      GET_PIECE_LIST_FOR_BRAND_SUCCESS,
      GET_PIECE_LIST_FOR_BRAND_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetBrandState = () => {
  return {
    type: RESET_BRAND_STATE,
  };
};

export const toggleBrandListDisplay = (display) => {
  return {
    type: TOGGLE_BRAND_LIST_DISPLAY,
    display,
  };
};
