class Image {
  constructor(data) {
    this._id = data._id;
    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;
  }
}

export default Image;
