class Author {
  constructor(data) {
    this._id = data._id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.summary = data.summary;
    this.bio = data.bio;

    this.website_url = data.website_url;
  }
}

export default Author;
