import {
  GET_ARTICLE_LIST_FOR_AUTHOR,
  GET_ARTICLE_LIST_FOR_AUTHOR_FAILURE,
  GET_ARTICLE_LIST_FOR_AUTHOR_SUCCESS,
  GET_AUTHOR,
  GET_AUTHOR_FAILURE,
  GET_AUTHOR_LIST,
  GET_AUTHOR_LIST_FAILURE,
  GET_AUTHOR_LIST_SUCCESS,
  GET_AUTHOR_SUCCESS,
} from "../actions/author";

const INITIAL_STATE = {
  isLoadingAuthorList: true,
  authors: [],
  hasMoreAuthors: true,

  isLoadingArticles: true,
  articles: [],
  hasMoreArticles: true,

  error: null,

  isLoadingAuthor: true,
  author: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUTHOR_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
      break;
    case GET_AUTHOR_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuthorList: false,
        authors: [...state.authors, ...action.payload.data],
        hasMoreAuthors: action.payload.data.length === 25,
      };
      break;
    case GET_AUTHOR_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuthorList: false,
        error: action.error.response.data,
        hasMoreAuthors: false,
      };

    case GET_AUTHOR:
      return { ...INITIAL_STATE };
    case GET_AUTHOR_SUCCESS:
      return {
        ...state,
        isLoadingAuthor: false,
        author: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionProducts: true,
      };
    case GET_AUTHOR_FAILURE:
      return {
        ...state,
        isLoadingAuthor: false,
        error: action.error.response.data,
      };

    case GET_ARTICLE_LIST_FOR_AUTHOR:
      return { ...state };
    case GET_ARTICLE_LIST_FOR_AUTHOR_SUCCESS:
      return {
        ...state,
        isLoadingArticles: false,
        articles: [...state.articles, ...action.payload.data],
        hasMoreArticles: action.payload.data.length === 25,
      };
    case GET_ARTICLE_LIST_FOR_AUTHOR_FAILURE:
      return {
        ...state,
        isLoadingArticles: false,
        error: action.error.response.data,
        hasMoreArticles: false,
      };
    default:
      return state;
  }
};
