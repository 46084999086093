import {
  GET_PIECE_LIST_FOR_USER,
  GET_PIECE_LIST_FOR_USER_FAILURE,
  GET_PIECE_LIST_FOR_USER_SUCCESS,
  GET_USER,
  GET_USER_FAILURE,
  GET_USER_LIST,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_SUCCESS,
  GET_USER_SUCCESS,
} from "../actions/user";

const INITIAL_STATE = {
  isLoadingUserList: true,
  users: [],
  hasMoreUsers: true,

  isLoadingProducts: true,
  products: [],
  hasMoreProducts: true,

  error: null,

  isLoadingUser: true,
  user: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUserList: false,
        users: [...state.users, ...action.payload.data],
        hasMoreUsers: action.payload.data.length === 25,
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        isLoadingUserList: false,
        error: action.error.response.data,
        hasMoreUsers: false,
      };

    case GET_USER:
      return { ...INITIAL_STATE };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionProducts: true,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoadingUser: false,
        error: action.error.response.data,
      };

    case GET_PIECE_LIST_FOR_USER:
      return { ...state };
    case GET_PIECE_LIST_FOR_USER_SUCCESS:
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    case GET_PIECE_LIST_FOR_USER_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };
    default:
      return state;
  }
};
