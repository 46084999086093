import {
  GET_INGREDIENT,
  GET_INGREDIENT_FAILURE,
  GET_INGREDIENT_LIST,
  GET_INGREDIENT_LIST_FAILURE,
  GET_INGREDIENT_LIST_SUCCESS,
  GET_INGREDIENT_SUCCESS,
  GET_PRODUCT_LIST_FOR_INGREDIENT,
  GET_PRODUCT_LIST_FOR_INGREDIENT_FAILURE,
  GET_PRODUCT_LIST_FOR_INGREDIENT_SUCCESS,
} from "../actions/ingredient";

const INITIAL_STATE = {
  isLoadingUserList: true,
  ingredients: [],
  hasMoreUsers: true,

  isLoadingProducts: true,
  products: [],
  hasMoreProducts: true,

  error: null,

  isLoadingUser: true,
  ingredient: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INGREDIENT_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
      break;
    case GET_INGREDIENT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUserList: false,
        ingredients: [...state.ingredients, ...action.payload.data],
        hasMoreUsers: action.payload.data.length === 25,
      };
      break;
    case GET_INGREDIENT_LIST_FAILURE:
      return {
        ...state,
        isLoadingUserList: false,
        error: action.error.response.data,
        hasMoreUsers: false,
      };

    case GET_INGREDIENT:
      return { ...INITIAL_STATE };
    case GET_INGREDIENT_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        ingredient: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionProducts: true,
      };
    case GET_INGREDIENT_FAILURE:
      return {
        ...state,
        isLoadingUser: false,
        error: action.error.response.data,
      };

    case GET_PRODUCT_LIST_FOR_INGREDIENT:
      return { ...state };
    case GET_PRODUCT_LIST_FOR_INGREDIENT_SUCCESS:
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    case GET_PRODUCT_LIST_FOR_INGREDIENT_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };
    default:
      return state;
  }
};
