import { List, Map } from "immutable";

const INITIAL_STATE = Map({
  display: "table",

  isLoading: true,

  products: List(),
  brands: List(),
  galleries: List(),
  locations: List(),
  data: List(),

  hasMore: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
