import placeholder from "../images/placeholder.png";
import Image from "./image";
import AdvertisementPartner from "./advertisement_partner";

class Advertisement {
  constructor(data) {
    this._id = data._id;

    this.title = data.title;

    this.body = data.body;

    this.images = data.images && data.images.map((a) => new Image(a));

    this.number_of_impressions = data.number_of_impressions;
    this.number_of_clicks = data.number_of_clicks;

    this.url = data.url;

    this.status = data.status;
    this.pricing_model = data.pricing_model;

    this.action_text = data.action_text;

    this.cost_per_impression = data.cost_per_impression || 0;
    this.cost_per_click = data.cost_per_click || 0;
    this.cost_per_unit = data.cost_per_unit || 0;

    this.advertisement_partner =
      data.advertisement_partner &&
      new AdvertisementPartner(data.advertisement_partner);

    this.priority = data.priority;

    this.positions = data.positions;
  }

  imageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.image_url;
    }
    return placeholder;
  }

  smallImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.small_image_url || image.image_url;
    }
    return placeholder;
  }

  mediumImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.medium_image_url || image.image_url;
    }
    return placeholder;
  }
}

export default Advertisement;
