import * as Sentry from "@sentry/react";
import {
  ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_SUCCESS,
  DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_ARTIST_LIST_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  GET_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_PROFESSIONAL,
  UPDATE_CURRENT_USER_PROFESSIONAL_FAILURE,
  UPDATE_CURRENT_USER_PROFESSIONAL_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
} from "../actions/me";

import { Mixpanel } from "../../utils/Mixpanel";

const INITIAL_STATE = {
  isLoading: true,
  error: null,
  hasMoreItems: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      return { ...state };
    case GET_CURRENT_USER_SUCCESS:
      const user = action.payload.data;

      Mixpanel.identify(user._id, {
        account_level: user.account_level,
        number_of_products: user.number_of_products,
      });

      Sentry.setUser({
        id: user._id,
        email: user.email,
        username: user.name,
      });

      return {
        ...state,
        user,
        isLoading: false,
      };
    case GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        error: action.error.response.data,
      };

    case GET_CURRENT_USER_ARTIST_LIST_SUCCESS:
      const brands = action.payload.data;

      return {
        ...state,
        brands,
        isLoading: false,
      };
    case GET_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS:
      const professionals = action.payload.data;

      return {
        ...state,
        professionals,
        isLoading: false,
      };
      break;

    // UPDATE

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        isUpdating: true,
        isUpdatingError: null,
      };
    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        user: action.payload.data,
      };
    case UPDATE_CURRENT_USER_FAILURE:
      return {
        ...state,
        isUpdatingError: false,
        updateError: action.error.response.data,
      };

    case UPDATE_CURRENT_USER_PROFESSIONAL:
      return {
        ...state,
        isUpdatingProfessional: true,
        updateProfessionalError: null,
      };
    case UPDATE_CURRENT_USER_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        isUpdatingProfessional: false,
        user: {
          ...state.user,
          professional: action.payload.data,
        },
      };
    case UPDATE_CURRENT_USER_PROFESSIONAL_FAILURE:
      return {
        ...state,
        isUpdatingProfessional: false,
        updateProfessionalError: action.error.response.data,
      };

    case ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
      };
    case DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_SUCCESS: {
      const user = action.payload.data;
      const brands = (state.brands || []).filter((a) =>
        user.brands.includes(a._id),
      );

      return {
        ...state,
        user,
        brands,
      };
    }
    default:
      return state;
  }
};
