import {
  CREATE_USER_TRAIT,
  CREATE_USER_TRAIT_FAILURE,
  CREATE_USER_TRAIT_SUCCESS,
  GET_USER_TRAIT,
  GET_USER_TRAIT_FAILURE,
  GET_USER_TRAIT_SUCCESS,
  RESET_USER_TRAIT_STATE,
  UPDATE_USER_TRAIT,
  UPDATE_USER_TRAIT_FAILURE,
  UPDATE_USER_TRAIT_SUCCESS,
} from "../actions/user_trait";

import { Mixpanel } from "../../utils/Mixpanel";
import UserTrait from "../../models/user_trait";

const INITIAL_STATE = {
  isLoadingUserTraitList: true,
  userTraits: [],
  hasMoreUserTraits: true,

  isLoadingUserTrait: true,
  isCreatingUserTrait: false,
  isUpdatingUserTrait: false,

  userTrait: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_USER_TRAIT_STATE:
    case GET_USER_TRAIT:
      return { ...INITIAL_STATE };
    case GET_USER_TRAIT_SUCCESS:
      return {
        ...state,
        isLoadingUserTrait: false,
        userTrait: new UserTrait(action.payload.data),
      };
    case GET_USER_TRAIT_FAILURE:
      return {
        ...state,
        isLoadingUserTrait: false,
        error: action.error.response.data,
      };

    case CREATE_USER_TRAIT:
      return {
        ...state,
        error: null,
        isCreatingUserTrait: true,
      };
    case CREATE_USER_TRAIT_SUCCESS: {
      Mixpanel.track("userTrait_created");
      return {
        ...state,
        isCreatingUserTrait: false,
        userTrait: action.payload.data,
      };
    }
    case CREATE_USER_TRAIT_FAILURE:
      return {
        ...state,
        isCreatingUserTrait: false,
        error: action.error.response.data,
      };

    case UPDATE_USER_TRAIT:
      return {
        ...state,
        isUpdatingUserTrait: true,
      };
    case UPDATE_USER_TRAIT_SUCCESS: {
      const userTrait = action.payload.data;
      Mixpanel.track("userTrait_updated", {
        category: userTrait.category,
        brand: userTrait.brand && userTrait.brand._id,
        gallery: userTrait.gallery && userTrait.gallery._id,
      });
      return {
        ...state,
        isUpdatingUserTrait: false,
        userTrait: new UserTrait(action.payload.data),
      };
    }
    case UPDATE_USER_TRAIT_FAILURE:
      return {
        ...state,
        isUpdatingUserTrait: false,
        error: action.error.response.data,
      };
    default:
      return state;
  }
};
