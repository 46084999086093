const url = "/api/v1/article_collections";

// Actions
export const RESET_ARTICLE_COLLECTION_STATE = "RESET_ARTICLE_COLLECTION_STATE";

export const GET_ARTICLE_COLLECTION_LIST = "GET_ARTICLE_COLLECTION_LIST";
export const GET_ARTICLE_COLLECTION_LIST_SUCCESS =
  "GET_ARTICLE_COLLECTION_LIST_SUCCESS";
export const GET_ARTICLE_COLLECTION_LIST_FAILURE =
  "GET_ARTICLE_COLLECTION_LIST_FAILURE";

export const GET_ARTICLE_COLLECTION = "GET_ARTICLE_COLLECTION";
export const GET_ARTICLE_COLLECTION_SUCCESS = "GET_ARTICLE_COLLECTION_SUCCESS";
export const GET_ARTICLE_COLLECTION_FAILURE = "GET_ARTICLE_COLLECTION_FAILURE";

export const CREATE_ARTICLE_COLLECTION = "CREATE_ARTICLE_COLLECTION";
export const CREATE_ARTICLE_COLLECTION_SUCCESS =
  "CREATE_ARTICLE_COLLECTION_SUCCESS";
export const CREATE_ARTICLE_COLLECTION_FAILURE =
  "CREATE_ARTICLE_COLLECTION_FAILURE";

export const UPDATE_ARTICLE_COLLECTION = "UPDATE_ARTICLE_COLLECTION";
export const UPDATE_ARTICLE_COLLECTION_SUCCESS =
  "UPDATE_ARTICLE_COLLECTION_SUCCESS";
export const UPDATE_ARTICLE_COLLECTION_FAILURE =
  "UPDATE_ARTICLE_COLLECTION_FAILURE";

export const DELETE_ARTICLE_COLLECTION = "DELETE_ARTICLE_COLLECTION";
export const DELETE_ARTICLE_COLLECTION_SUCCESS =
  "DELETE_ARTICLE_COLLECTION_SUCCESS";
export const DELETE_ARTICLE_COLLECTION_FAILURE =
  "DELETE_ARTICLE_COLLECTION_FAILURE";

export const TOGGLE_ARTICLE_COLLECTION_LIST_DISPLAY =
  "TOGGLE_ARTICLE_COLLECTION_LIST_DISPLAY";

export const GET_ARTICLES_FOR_ARTICLE_COLLECTION =
  "GET_ARTICLES_FOR_ARTICLE_COLLECTION";
export const GET_ARTICLES_FOR_ARTICLE_COLLECTION_SUCCESS =
  "GET_ARTICLES_FOR_ARTICLE_COLLECTION_SUCCESS";
export const GET_ARTICLES_FOR_ARTICLE_COLLECTION_FAILURE =
  "GET_ARTICLES_FOR_ARTICLE_COLLECTION_FAILURE";

export const ADD_ARTICLE_TO_ARTICLE_COLLECTION =
  "ADD_ARTICLE_TO_ARTICLE_COLLECTION";
export const ADD_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS =
  "ADD_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS";
export const ADD_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE =
  "ADD_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE";

export const REMOVE_ARTICLE_TO_ARTICLE_COLLECTION =
  "REMOVE_ARTICLE_TO_ARTICLE_COLLECTION";
export const REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS =
  "REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS";
export const REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE =
  "REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE";

// NETWORKING

export const getArticleCollectionList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_ARTICLE_COLLECTION_LIST,
      GET_ARTICLE_COLLECTION_LIST_SUCCESS,
      GET_ARTICLE_COLLECTION_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getArticleCollection = (id) => {
  return {
    types: [
      GET_ARTICLE_COLLECTION,
      GET_ARTICLE_COLLECTION_SUCCESS,
      GET_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createArticleCollection = (data) => {
  return {
    types: [
      CREATE_ARTICLE_COLLECTION,
      CREATE_ARTICLE_COLLECTION_SUCCESS,
      CREATE_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateArticleCollection = (id, data) => {
  return {
    types: [
      UPDATE_ARTICLE_COLLECTION,
      UPDATE_ARTICLE_COLLECTION_SUCCESS,
      UPDATE_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const patchArticleCollection = (id, data) => {
  return {
    types: [
      UPDATE_ARTICLE_COLLECTION,
      UPDATE_ARTICLE_COLLECTION_SUCCESS,
      UPDATE_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteArticleCollection = (id) => {
  return {
    types: [
      DELETE_ARTICLE_COLLECTION,
      DELETE_ARTICLE_COLLECTION_SUCCESS,
      DELETE_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getArticlesForArticleCollection = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_ARTICLES_FOR_ARTICLE_COLLECTION,
      GET_ARTICLES_FOR_ARTICLE_COLLECTION_SUCCESS,
      GET_ARTICLES_FOR_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/articles`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const addArticleToArticleCollection = (id, data) => {
  return {
    types: [
      ADD_ARTICLE_TO_ARTICLE_COLLECTION,
      ADD_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS,
      ADD_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/articles`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteArticleFromArticleCollection = (id, articleId) => {
  return {
    types: [
      REMOVE_ARTICLE_TO_ARTICLE_COLLECTION,
      REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_SUCCESS,
      REMOVE_ARTICLE_TO_ARTICLE_COLLECTION_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/articles/${articleId}`,
        method: "DELETE",
      },
    },
  };
};

export const resetArticleCollectionState = () => {
  return {
    type: RESET_ARTICLE_COLLECTION_STATE,
  };
};

export const toggleArticleCollectionListDisplay = (display) => {
  return {
    type: TOGGLE_ARTICLE_COLLECTION_LIST_DISPLAY,
    display,
  };
};
