import User from "./user";

class Comment {
  constructor(data) {
    this._id = data._id;
    this.body = data.body;
    this.user = data.user && new User(data.user);
    this.number_of_likes = data.likes_count;
    this.number_of_comments = data.comments_count;
  }
}

export default Comment;
