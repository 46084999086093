import Permission from "./permission";

class User {
  constructor(data) {
    this._id = data._id;
    this.name = data.name;
    this.username = data.username;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.instagram_username = data.instagram_username;
    this.twitter_username = data.twitter_username;

    this.account_level = data.account_level;

    this.permissions = data.permissions && new Permission(data.permissions);

    this.is_onboared = data.is_onboarded;

    this.product_recommendation = data.product_recommendation;
  }
}

export default User;
