import Loadable from "react-loadable";
import { Loader } from "semantic-ui-react";
import { USERS_IN_NEED_OF_RECOMMENDATIONS_PATH as path } from "../../constants/paths";

export default {
  path,
  component: Loadable({
    loader: () => import("./components/UsersInNeedOfRecommendationsPage"),
    loading: Loader,
  }),
};
