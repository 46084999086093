const url = "/api/v1/products";

// Actions

export const RESET_PRODUCT_STATE = "RESET_PRODUCT_STATE";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const CREATE_PRODUCT_PDF = "CREATE_PRODUCT_PDF";
export const CREATE_PRODUCT_PDF_SUCCESS = "CREATE_PRODUCT_PDF_SUCCESS";
export const CREATE_PRODUCT_PDF_FAILURE = "CREATE_PRODUCT_PDF_FAILURE";

export const DELETE_PRODUCT_EXHIBITION = "DELETE_PRODUCT_EXHIBITION";
export const DELETE_PRODUCT_EXHIBITION_SUCCESS =
  "DELETE_PRODUCT_EXHIBITION_SUCCESS";
export const DELETE_PRODUCT_EXHIBITION_FAILURE =
  "DELETE_PRODUCT_EXHIBITION_FAILURE";

export const FILTER_PRODUCT_LIST_BY_ARTIST = "FILTER_PRODUCT_LIST_BY_ARTIST";
export const FILTER_PRODUCT_LIST_BY_GALLERY = "FILTER_PRODUCT_LIST_BY_GALLERY";
export const FILTER_PRODUCT_LIST_BY_TEXT = "FILTER_PRODUCT_LIST_BY_TEXT";
export const FILTER_PRODUCT_LIST_BY_TAG = "FILTER_PRODUCT_LIST_BY_TAG";
export const FILTER_PRODUCT_LIST_BY_CATEGORY =
  "FILTER_PRODUCT_LIST_BY_CATEGORY";

export const SORT_PRODUCT_LIST_BY_KEY = "SORT_PRODUCTS";
export const TOGGLE_PRODUCT_LIST_DISPLAY = "TOGGLE_PRODUCT_LIST_DISPLAY";
export const SET_PRODUCT_LIST_FILTERS = "SET_PRODUCT_LIST_FILTERS";

// NEW

export const SELECT_PRODUCTS_FIELD = "SELECT_PRODUCTS_FIELD";
export const UNSELECT_PRODUCTS_FIELD = "UNSELECT_PRODUCTS_FIELD";

export const ADD_PRODUCTS_FILTER = "ADD_PRODUCTS_FILTER";
export const REMOVE_PRODUCTS_FILTER = "REMOVE_PRODUCTS_FILTER";
export const CLEAR_PRODUCTS_FILTERS = "CLEAR_PRODUCTS_FILTERS";
export const CHANGE_PRODUCTS_FILTER_FIELD = "CHANGE_PRODUCTS_FILTER_FIELD";
export const CHANGE_PRODUCTS_FILTER_OPERATOR =
  "CHANGE_PRODUCTS_FILTER_OPERATOR";
export const CHANGE_PRODUCTS_FILTER_VALUE = "CHANGE_PRODUCTS_FILTER_VALUE";

export const GET_PRODUCT_LIST_FOR_RELATED_PRODUCT =
  "GET_PRODUCT_LIST_FOR_RELATED_PRODUCT";
export const GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_SUCCESS =
  "GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_SUCCESS";
export const GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_FAILURE =
  "GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_FAILURE";

export const ADD_PRODUCT_TO_RELATED_PRODUCT = "ADD_PRODUCT_TO_RELATED_PRODUCT";
export const ADD_PRODUCT_TO_RELATED_PRODUCT_SUCCESS =
  "ADD_PRODUCT_TO_RELATED_PRODUCT_SUCCESS";
export const ADD_PRODUCT_TO_RELATED_PRODUCT_FAILURE =
  "ADD_PRODUCT_TO_RELATED_PRODUCT_FAILURE";

export const DELETE_PRODUCT_FROM_RELATED_PRODUCT =
  "DELETE_PRODUCT_FROM_RELATED_PRODUCT";
export const DELETE_PRODUCT_FROM_RELATED_PRODUCT_SUCCESS =
  "DELETE_PRODUCT_FROM_RELATED_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FROM_RELATED_PRODUCT_FAILURE =
  "DELETE_PRODUCT_FROM_RELATED_PRODUCT_FAILURE";

// Networking

export const getProductList = (
  page = 1,
  limit = 250,
  sort = "created_at",
  direction,
) => {
  return {
    types: [
      GET_PRODUCT_LIST,
      GET_PRODUCT_LIST_SUCCESS,
      GET_PRODUCT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getProduct = (id) => {
  return {
    types: [GET_PRODUCT, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createProduct = (data) => {
  return {
    types: [CREATE_PRODUCT, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILURE],
    payload: {
      request: {
        url,

        method: "POST",
        data,
      },
    },
  };
};

export const updateProduct = (id, data) => {
  return {
    types: [UPDATE_PRODUCT, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,

        method: "PATCH",
        data,
      },
    },
  };
};

export const createProductPDF = (id, data) => {
  return {
    types: [
      CREATE_PRODUCT_PDF,
      CREATE_PRODUCT_PDF_SUCCESS,
      CREATE_PRODUCT_PDF_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/pdf`,

        method: "POST",
        data,
      },
    },
  };
};

export const deleteProduct = (id) => {
  return {
    types: [DELETE_PRODUCT, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,

        method: "DELETE",
      },
    },
  };
};

export const getRelatedProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PRODUCT_LIST_FOR_RELATED_PRODUCT,
      GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_SUCCESS,
      GET_PRODUCT_LIST_FOR_RELATED_PRODUCT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/related_products`,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const addRelatedProduct = (id, data) => {
  return {
    types: [
      ADD_PRODUCT_TO_RELATED_PRODUCT,
      ADD_PRODUCT_TO_RELATED_PRODUCT_SUCCESS,
      ADD_PRODUCT_TO_RELATED_PRODUCT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/related_products`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteRelatedProduct = (id, productId) => {
  return {
    types: [
      DELETE_PRODUCT_FROM_RELATED_PRODUCT,
      DELETE_PRODUCT_FROM_RELATED_PRODUCT_SUCCESS,
      DELETE_PRODUCT_FROM_RELATED_PRODUCT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/related_products/${productId}`,
        method: "DELETE",
      },
    },
  };
};

export const toggleProductListDisplay = (display) => {
  return {
    type: TOGGLE_PRODUCT_LIST_DISPLAY,
    display,
  };
};

export const resetProduct = () => {
  return {
    type: RESET_PRODUCT_STATE,
  };
};
