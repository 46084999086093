import Image from "./image";
import placeholder from "../images/placeholder.png";
import User from "./user";

class Post {
  constructor(data) {
    this._id = data._id;
    this.body = data.body;
    this.user = data.user && new User(data.user);
    this.images = data.images && data.images.map((a) => new Image(a));
    this.number_of_likes = data.likes_count;
    this.number_of_comments = data.comments_count;
  }

  imageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.image_url;
    }
    return placeholder;
  }

  smallImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.small_image_url || image.image_url;
    }
    return placeholder;
  }

  mediumImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.medium_image_url || image.image_url;
    }
    return placeholder;
  }
}

export default Post;
