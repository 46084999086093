import {
  GET_AUTHOR_LIST,
  GET_AUTHOR_LIST_FAILURE,
  GET_AUTHOR_LIST_SUCCESS,
} from "../actions/author";

const INITIAL_STATE = {
  isLoadingAuthorList: true,
  authors: [],
  hasMoreAuthors: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUTHOR_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_AUTHOR_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAuthorList: false,
        authors: [...state.authors, ...action.payload.data],
        hasMoreAuthors: action.payload.data.length === 25,
      };
    case GET_AUTHOR_LIST_FAILURE:
      return {
        ...state,
        isLoadingAuthorList: false,
        error: action.error.response.data,
        hasMoreAuthors: false,
      };
    default:
      return state;
  }
};
