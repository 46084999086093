import {
  CREATE_ARTICLE,
  CREATE_ARTICLE_FAILURE,
  CREATE_ARTICLE_PDF,
  CREATE_ARTICLE_PDF_FAILURE,
  CREATE_ARTICLE_PDF_SUCCESS,
  CREATE_ARTICLE_SUCCESS,
  GET_ARTICLE,
  GET_ARTICLE_FAILURE,
  GET_ARTICLE_SUCCESS,
  RESET_ARTICLE_STATE,
  UPDATE_ARTICLE,
  UPDATE_ARTICLE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
} from "../actions/article";

import { Mixpanel } from "../../utils/Mixpanel";
import Article from "../../models/article";

const INITIAL_STATE = {
  display: "table",

  isLoadingArticleList: true,
  articles: [],
  hasMoreArticles: true,

  isLoadingArticle: true,
  isCreatingArticle: false,
  isUpdatingArticle: false,

  isCreatingArticlePDF: false,

  article: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ARTICLE_STATE:
    case GET_ARTICLE:
      return { ...INITIAL_STATE };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoadingArticle: false,
        article: new Article(action.payload.data),
      };
    case GET_ARTICLE_FAILURE:
      return {
        ...state,
        isLoadingArticle: false,
        error: action.error.response.data,
      };

    case CREATE_ARTICLE:
      return {
        ...state,
        error: null,
        isCreatingArticle: true,
      };
    case CREATE_ARTICLE_SUCCESS: {
      Mixpanel.track("article_created");
      return {
        ...state,
        isCreatingArticle: false,
        article: action.payload.data,
      };
    }
    case CREATE_ARTICLE_FAILURE:
      return {
        ...state,
        isCreatingArticle: false,
        error: action.error.response.data,
      };

    case UPDATE_ARTICLE:
      return {
        ...state,
        isUpdatingArticle: true,
      };
    case UPDATE_ARTICLE_SUCCESS: {
      const article = action.payload.data;
      Mixpanel.track("article_updated", {
        category: article.category,
        brand: article.brand && article.brand._id,
        gallery: article.gallery && article.gallery._id,
      });
      return {
        ...state,
        isUpdatingArticle: false,
        article: new Article(action.payload.data),
      };
    }
    case UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        isUpdatingArticle: false,
        error: action.error.response.data,
      };

    case CREATE_ARTICLE_PDF:
      return {
        ...state,
        isCreatingArticlePDF: true,
      };
    case CREATE_ARTICLE_PDF_SUCCESS:
    case CREATE_ARTICLE_PDF_FAILURE:
      return {
        ...state,
        isCreatingArticlePDF: false,
      };

    default:
      return state;
  }
};
