import Post from "./post";
import User from "./user";
import Comment from "./comment";
import ComplaintReason from "./complaint_reason";

class Complaint {
  constructor(data) {
    this._id = data._id;
    this.post = data.post && new Post(data.post);
    this.comment = data.comment && new Comment(data.comment);
    this.user = data.user && new User(data.user);
    this.status = data.status;
    this.complaint_reason =
      data.complaint_reason && new ComplaintReason(data.complaint_reason);
  }
}

export default Complaint;
