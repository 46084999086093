import { fromJS, List, Map } from "immutable";

import {
  CREATE_POST_SUCCESS,
  GET_POST_LIST,
  GET_POST_LIST_FAILURE,
  GET_POST_LIST_SUCCESS,
  TOGGLE_POST_LIST_DISPLAY,
  UPDATE_POST_SUCCESS,
} from "../actions/post";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingPostList: true,
  posts: List(),
  hasMorePosts: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_POST_LIST:
      return state
        .set("isLoadingPostList", true)
        .set(
          "posts",
          action.payload.request.params.page <= 1 ? List() : state.get("posts"),
        );
    case GET_POST_LIST_SUCCESS:
      return state
        .set("isLoadingPostList", false)
        .set("hasMorePosts", false)
        .update("posts", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_POST_LIST_FAILURE:
      return state
        .set("isLoadingPostList", false)
        .set("hasMorePosts", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_POST_SUCCESS:
      return state.update("posts", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_POST_SUCCESS: {
      const index = state
        .get("posts")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(["posts", index], fromJS(action.payload.data));
    }
    case TOGGLE_POST_LIST_DISPLAY: {
      return state.set("display", action.display);
    }
    default:
      return state;
  }
};
