import {
  GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION,
  GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_FAILURE,
  GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_SUCCESS,
  GET_ARTICLE_SECTION,
  GET_ARTICLE_SECTION_FAILURE,
  GET_ARTICLE_SECTION_SUCCESS,
} from "../actions/article_section";

const INITIAL_STATE = {
  isLoadingArticleCollectionList: true,
  articleCollections: [],

  isLoadingArticleSection: true,
  articleSection: null,

  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTICLE_SECTION:
      return { ...INITIAL_STATE };
    case GET_ARTICLE_SECTION_SUCCESS:
      return {
        ...state,
        isLoadingArticleSection: false,
        articleSection: action.payload.data,
      };
    case GET_ARTICLE_SECTION_FAILURE:
      return {
        ...state,
        isLoadingArticleSection: false,
        error: action.error.response.data,
      };

    case GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION:
      return { ...state };
    case GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_SUCCESS:
      return {
        ...state,
        isLoadingArticleCollections: false,
        articleCollections: [
          ...state.articleCollections,
          ...action.payload.data,
        ],
        hasMoreArticleCollections: action.payload.data.length === 25,
      };
    case GET_ARTICLE_COLLECTIONS_FOR_ARTICLE_SECTION_FAILURE:
      return {
        ...state,
        isLoadingArticleCollections: false,
        error: action.error.response.data,
        hasMoreArticleCollections: false,
      };
    default:
      return state;
  }
};
