const url = "/api/v1/invoices";

// Actions

export const RESET_INVOICE_STATE = "RESET_INVOICE_STATE";

export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILURE = "GET_INVOICE_LIST_FAILURE";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILURE = "CREATE_INVOICE_FAILURE";

export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "UPDATE_INVOICE_FAILURE";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";

// NETWORKING

export const getInvoiceList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_INVOICE_LIST,
      GET_INVOICE_LIST_SUCCESS,
      GET_INVOICE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getInvoice = (id) => {
  return {
    types: [GET_INVOICE, GET_INVOICE_SUCCESS, GET_INVOICE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createInvoice = (data) => {
  return {
    types: [CREATE_INVOICE, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateInvoice = (id, data) => {
  return {
    types: [UPDATE_INVOICE, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchInvoice = (id, data) => {
  return {
    types: [UPDATE_INVOICE, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteInvoice = (id) => {
  return {
    types: [DELETE_INVOICE, DELETE_INVOICE_SUCCESS, DELETE_INVOICE_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const resetInvoiceState = () => {
  return {
    type: RESET_INVOICE_STATE,
  };
};
