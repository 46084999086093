import {
  GET_PRODUCT_RECOMMENDATION_LIST,
  GET_PRODUCT_RECOMMENDATION_LIST_FAILURE,
  GET_PRODUCT_RECOMMENDATION_LIST_SUCCESS,
} from "../actions/product_recommendation";

const INITIAL_STATE = {
  isLoadingProductRecommendationList: true,
  productRecommendations: [],
  hasMoreProductRecommendations: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_RECOMMENDATION_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case GET_PRODUCT_RECOMMENDATION_LIST_SUCCESS:
      return {
        ...state,
        isLoadingProductRecommendationList: false,
        productRecommendations: [
          ...state.productRecommendations,
          ...action.payload.data,
        ],
        hasMoreProductRecommendations: action.payload.data.length === 25,
      };
    case GET_PRODUCT_RECOMMENDATION_LIST_FAILURE:
      return {
        ...state,
        isLoadingProductRecommendationList: false,
        error: action.error.response.data,
        hasMoreProductRecommendations: false,
      };
    default:
      return state;
  }
};
