export const NOT_ADMIN_PATH = "/not_admin";
export const ACCOUNT_PATH = "/account";
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const PASSWORD_RECOVER_PATH = "/password_recover";
export const PASSWORD_RESET_PATH = "/password_reset";

export const DASHBOARD_PATH = "/";
export const BRAND_PATH = "/brands";
export const PRODUCT_PATH = "/products";
export const PRODUCT_RECOMMENDATION_PATH = "/product_recommendations";
export const USER_PATH = "/users";
export const COMPLAINT_PATH = "/complaints";
export const INGREDIENT_PATH = "/ingredients";
export const FEED_PATH = "/feeds";
export const FEED_POSTS_PATH = "/feed_posts";

export const ARTICLES_PATH = "/articles";
export const ARTICLE_SECTIONS_PATH = "/article_sections";
export const ARTICLE_COLLECTIONS_PATH = "/article_collections";
export const ADVERTISEMENTS_PATH = "/advertisements";
export const ADVERTISEMENT_PARTNERS_PATH = "/advertisement_partners";
export const INVOICES_PATH = "/invoices";

export const HAIR_STYLES_PATH = "/curl_patterns";

export const DEDUPLICATE_PRODUCTS_PATH = "/deduplicate_products";
export const PRODUCTS_IN_NEED_OF_IMAGES_PATH = "/products_in_need_of_images";
export const USERS_IN_NEED_OF_RECOMMENDATIONS_PATH =
  "/users_in_need_of_recommendations";
export const AUTHORS_PATH = "/authors";

export const PRODUCT_ARTICLE_PATH = "/product_articles";
export const POST_PATH = "/posts";
export const COMMENT_PATH = "/comments";

export const USER_TRAIT_PATH = "/user_traits";
