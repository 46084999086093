const url = "/api/v1/comments";

// Actions

export const RESET_COMMENT_STATE = "RESET_COMMENT_STATE";
export const TOGGLE_COMMENT_LIST_DISPLAY = "TOGGLE_COMMENT_LIST_DISPLAY";

export const GET_COMMENT_LIST = "GET_COMMENT_LIST";
export const GET_COMMENT_LIST_SUCCESS = "GET_COMMENT_LIST_SUCCESS";
export const GET_COMMENT_LIST_FAILURE = "GET_COMMENT_LIST_FAILURE";

export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const GET_COMMENT_FAILURE = "GET_COMMENT_FAILURE";

export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const GET_COMMENT_COMMENT_LIST = "GET_COMMENT_COMMENT_LIST";
export const GET_COMMENT_COMMENT_LIST_SUCCESS =
  "GET_COMMENT_COMMENT_LIST_SUCCESS";
export const GET_COMMENT_COMMENT_LIST_FAILURE =
  "GET_COMMENT_COMMENT_LIST_FAILURE";

// Networking

export const getCommentList = (
  page = 1,
  limit = 250,
  sort = "created_at",
  direction,
) => {
  return {
    types: [
      GET_COMMENT_LIST,
      GET_COMMENT_LIST_SUCCESS,
      GET_COMMENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getComment = (id) => {
  return {
    types: [GET_COMMENT, GET_COMMENT_SUCCESS, GET_COMMENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createComment = (data) => {
  return {
    types: [CREATE_COMMENT, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAILURE],
    payload: {
      request: {
        url,

        method: "COMMENT",
        data,
      },
    },
  };
};

export const updateComment = (id, data) => {
  return {
    types: [UPDATE_COMMENT, UPDATE_COMMENT_SUCCESS, UPDATE_COMMENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteComment = (id) => {
  return {
    types: [UPDATE_COMMENT, UPDATE_COMMENT_SUCCESS, UPDATE_COMMENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getCommentCommentList = (id) => {
  return {
    types: [
      GET_COMMENT_COMMENT_LIST,
      GET_COMMENT_COMMENT_LIST_SUCCESS,
      GET_COMMENT_COMMENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/comments`,
        method: "GET",
      },
    },
  };
};

export const toggleCommentListDisplay = (display) => {
  return {
    type: TOGGLE_COMMENT_LIST_DISPLAY,
    display,
  };
};

export const resetComment = () => {
  return {
    type: RESET_COMMENT_STATE,
  };
};
