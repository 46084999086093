class Brand {
  constructor(data) {
    this._id = data._id;
    this.name = data.name;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.about = data.about;
    this.website_url = data.website_url;
    this.instagram_username = data.instagram_username;
    this.twitter_username = data.twitter_username;
  }
}

export default Brand;
