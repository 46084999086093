import { fromJS, List, Map } from "immutable";

import {
  CREATE_ADVERTISEMENT_SUCCESS,
  GET_ADVERTISEMENT_LIST,
  GET_ADVERTISEMENT_LIST_FAILURE,
  GET_ADVERTISEMENT_LIST_SUCCESS,
  TOGGLE_ADVERTISEMENT_LIST_DISPLAY,
  UPDATE_ADVERTISEMENT_SUCCESS,
} from "../actions/advertisement";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingAdvertisementList: true,
  advertisements: List(),
  hasMoreAdvertisements: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADVERTISEMENT_LIST:
      return state
        .set("isLoadingAdvertisementList", true)
        .set(
          "advertisements",
          action.payload.request.params.page <= 1
            ? List()
            : state.get("advertisements"),
        );
    case GET_ADVERTISEMENT_LIST_SUCCESS:
      return state
        .set("isLoadingAdvertisementList", false)
        .set("hasMoreAdvertisements", false)
        .update("advertisements", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_ADVERTISEMENT_LIST_FAILURE:
      return state
        .set("isLoadingAdvertisementList", false)
        .set("hasMoreAdvertisements", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_ADVERTISEMENT_SUCCESS:
      return state.update("advertisements", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_ADVERTISEMENT_SUCCESS: {
      const index = state
        .get("advertisements")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(
        ["advertisements", index],
        fromJS(action.payload.data),
      );
    }
    case TOGGLE_ADVERTISEMENT_LIST_DISPLAY: {
      return state.set("display", action.display);
    }
    default:
      return state;
  }
};
