import { fromJS, List, Map } from "immutable";

import {
  CREATE_COMPLAINT_SUCCESS,
  GET_COMPLAINT_LIST,
  GET_COMPLAINT_LIST_FAILURE,
  GET_COMPLAINT_LIST_SUCCESS,
  TOGGLE_COMPLAINT_LIST_DISPLAY,
  UPDATE_COMPLAINT_SUCCESS,
} from "../actions/complaint";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingComplaintList: true,
  complaints: List(),
  hasMoreComplaints: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPLAINT_LIST:
      return state
        .set("isLoadingComplaintList", true)
        .set(
          "complaints",
          action.payload.request.params.page <= 1
            ? List()
            : state.get("complaints"),
        );
    case GET_COMPLAINT_LIST_SUCCESS:
      return state
        .set("isLoadingComplaintList", false)
        .set("hasMoreComplaints", false)
        .update("complaints", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_COMPLAINT_LIST_FAILURE:
      return state
        .set("isLoadingComplaintList", false)
        .set("hasMoreComplaints", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_COMPLAINT_SUCCESS:
      return state.update("complaints", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_COMPLAINT_SUCCESS: {
      const index = state
        .get("complaints")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(["complaints", index], fromJS(action.payload.data));
    }
    case TOGGLE_COMPLAINT_LIST_DISPLAY: {
      return state.set("display", action.display);
    }
    default:
      return state;
  }
};
