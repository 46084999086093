const url = "/api/v1/product_articles";

// Actions

export const RESET_PRODUCT_ARTICLE_STATE = "RESET_PRODUCT_ARTICLE_STATE";

export const GET_PRODUCT_ARTICLE_LIST = "GET_PRODUCT_ARTICLE_LIST";
export const GET_PRODUCT_ARTICLE_LIST_SUCCESS =
  "GET_PRODUCT_ARTICLE_LIST_SUCCESS";
export const GET_PRODUCT_ARTICLE_LIST_FAILURE =
  "GET_PRODUCT_ARTICLE_LIST_FAILURE";

export const GET_PRODUCT_ARTICLE = "GET_PRODUCT_ARTICLE";
export const GET_PRODUCT_ARTICLE_SUCCESS = "GET_PRODUCT_ARTICLE_SUCCESS";
export const GET_PRODUCT_ARTICLE_FAILURE = "GET_PRODUCT_ARTICLE_FAILURE";

export const CREATE_PRODUCT_ARTICLE = "CREATE_PRODUCT_ARTICLE";
export const CREATE_PRODUCT_ARTICLE_SUCCESS = "CREATE_PRODUCT_ARTICLE_SUCCESS";
export const CREATE_PRODUCT_ARTICLE_FAILURE = "CREATE_PRODUCT_ARTICLE_FAILURE";

export const UPDATE_PRODUCT_ARTICLE = "UPDATE_PRODUCT_ARTICLE";
export const UPDATE_PRODUCT_ARTICLE_SUCCESS = "UPDATE_PRODUCT_ARTICLE_SUCCESS";
export const UPDATE_PRODUCT_ARTICLE_FAILURE = "UPDATE_PRODUCT_ARTICLE_FAILURE";

export const DELETE_PRODUCT_ARTICLE = "DELETE_PRODUCT_ARTICLE";
export const DELETE_PRODUCT_ARTICLE_SUCCESS = "DELETE_PRODUCT_ARTICLE_SUCCESS";
export const DELETE_PRODUCT_ARTICLE_FAILURE = "DELETE_PRODUCT_ARTICLE_FAILURE";

export const GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE =
  "GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE";
export const GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_SUCCESS =
  "GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_SUCCESS";
export const GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_FAILURE =
  "GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_FAILURE";

export const ADD_PRODUCT_TO_PRODUCT_ARTICLE = "ADD_PRODUCT_TO_PRODUCT_ARTICLE";
export const ADD_PRODUCT_TO_PRODUCT_ARTICLE_SUCCESS =
  "ADD_PRODUCT_TO_PRODUCT_ARTICLE_SUCCESS";
export const ADD_PRODUCT_TO_PRODUCT_ARTICLE_FAILURE =
  "ADD_PRODUCT_TO_PRODUCT_ARTICLE_FAILURE";

export const DELETE_PRODUCT_FROM_PRODUCT_ARTICLE =
  "DELETE_PRODUCT_FROM_PRODUCT_ARTICLE";
export const DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_SUCCESS =
  "DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_SUCCESS";
export const DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_FAILURE =
  "DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_FAILURE";

// NETWORKING

export const getProductArticleList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_PRODUCT_ARTICLE_LIST,
      GET_PRODUCT_ARTICLE_LIST_SUCCESS,
      GET_PRODUCT_ARTICLE_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getProductArticle = (id) => {
  return {
    types: [
      GET_PRODUCT_ARTICLE,
      GET_PRODUCT_ARTICLE_SUCCESS,
      GET_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createProductArticle = (data) => {
  return {
    types: [
      CREATE_PRODUCT_ARTICLE,
      CREATE_PRODUCT_ARTICLE_SUCCESS,
      CREATE_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateProductArticle = (id, data) => {
  return {
    types: [
      UPDATE_PRODUCT_ARTICLE,
      UPDATE_PRODUCT_ARTICLE_SUCCESS,
      UPDATE_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchProductArticle = (id, data) => {
  return {
    types: [
      UPDATE_PRODUCT_ARTICLE,
      UPDATE_PRODUCT_ARTICLE_SUCCESS,
      UPDATE_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteProductArticle = (id) => {
  return {
    types: [
      DELETE_PRODUCT_ARTICLE,
      DELETE_PRODUCT_ARTICLE_SUCCESS,
      DELETE_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getProductArticleProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE,
      GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_SUCCESS,
      GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const addProductToProductArticle = (id, data) => {
  return {
    types: [
      ADD_PRODUCT_TO_PRODUCT_ARTICLE,
      ADD_PRODUCT_TO_PRODUCT_ARTICLE_SUCCESS,
      ADD_PRODUCT_TO_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,
        method: "PUT",

        data,
      },
    },
  };
};

export const deleteProductFromProductArticle = (id, productId) => {
  return {
    types: [
      DELETE_PRODUCT_FROM_PRODUCT_ARTICLE,
      DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_SUCCESS,
      DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products/${productId}`,
        method: "DELETE",
      },
    },
  };
};

export const resetProductArticleState = () => {
  return {
    type: RESET_PRODUCT_ARTICLE_STATE,
  };
};
