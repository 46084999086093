import Loadable from "react-loadable";
import { Loader } from "semantic-ui-react";
import { PRODUCTS_IN_NEED_OF_IMAGES_PATH as path } from "../../constants/paths";

export default {
  path,
  component: Loadable({
    loader: () => import("./components/ProductsInNeedOfImagesPage"),
    loading: Loader,
  }),
};
