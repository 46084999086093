const url = "/api/v1/complaints";

// Actions

export const RESET_COMPLAINT_STATE = "RESET_COMPLAINT_STATE";

export const GET_COMPLAINT_LIST = "GET_COMPLAINT_LIST";
export const GET_COMPLAINT_LIST_SUCCESS = "GET_COMPLAINT_LIST_SUCCESS";
export const GET_COMPLAINT_LIST_FAILURE = "GET_COMPLAINT_LIST_FAILURE";

export const GET_COMPLAINT = "GET_COMPLAINT";
export const GET_COMPLAINT_SUCCESS = "GET_COMPLAINT_SUCCESS";
export const GET_COMPLAINT_FAILURE = "GET_COMPLAINT_FAILURE";

export const CREATE_COMPLAINT = "CREATE_COMPLAINT";
export const CREATE_COMPLAINT_SUCCESS = "CREATE_COMPLAINT_SUCCESS";
export const CREATE_COMPLAINT_FAILURE = "CREATE_COMPLAINT_FAILURE";

export const UPDATE_COMPLAINT = "UPDATE_COMPLAINT";
export const UPDATE_COMPLAINT_SUCCESS = "UPDATE_COMPLAINT_SUCCESS";
export const UPDATE_COMPLAINT_FAILURE = "UPDATE_COMPLAINT_FAILURE";

export const DELETE_COMPLAINT = "DELETE_COMPLAINT";
export const DELETE_COMPLAINT_SUCCESS = "DELETE_COMPLAINT_SUCCESS";
export const DELETE_COMPLAINT_FAILURE = "DELETE_COMPLAINT_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT =
  "GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT";
export const GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_COMPLAINT_FAILURE";

export const GET_ARTWORKS_LIST_FOR_COMPLAINT =
  "GET_ARTWORKS_LIST_FOR_COMPLAINT";
export const GET_ARTWORKS_LIST_FOR_COMPLAINT_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_COMPLAINT_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_COMPLAINT_FAILURE =
  "GET_ARTWORKS_LIST_FOR_COMPLAINT_FAILURE";

export const GET_PIECE_LIST_FOR_COMPLAINT = "GET_PIECE_LIST_FOR_COMPLAINT";
export const GET_PIECE_LIST_FOR_COMPLAINT_SUCCESS =
  "GET_PIECE_LIST_FOR_COMPLAINT_SUCCESS";
export const GET_PIECE_LIST_FOR_COMPLAINT_FAILURE =
  "GET_PIECE_LIST_FOR_COMPLAINT_FAILURE";

export const GET_EXHIBITION_LIST_FOR_COMPLAINT =
  "GET_EXHIBITION_LIST_FOR_COMPLAINT";
export const GET_EXHIBITION_LIST_FOR_COMPLAINT_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_COMPLAINT_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_COMPLAINT_FAILURE =
  "GET_EXHIBITION_LIST_FOR_COMPLAINT_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_COMPLAINT_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_COMPLAINT =
  "GET_SELL_THROUGH_RATES_FOR_COMPLAINT";
export const GET_SELL_THROUGH_RATES_FOR_COMPLAINT_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_COMPLAINT_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_COMPLAINT_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_COMPLAINT_FAILURE";

export const TOGGLE_COMPLAINT_LIST_DISPLAY = "TOGGLE_COMPLAINT_LIST_DISPLAY";

export const GET_SLPAE_FOR_COMPLAINT = "GET_SLPAE_FOR_COMPLAINT";
export const GET_SLPAE_FOR_COMPLAINT_SUCCESS =
  "GET_SLPAE_FOR_COMPLAINT_SUCCESS";
export const GET_SLPAE_FOR_COMPLAINT_FAILURE =
  "GET_SLPAE_FOR_COMPLAINT_FAILURE";

export const GET_PRICE_CHART_FOR_COMPLAINT = "GET_PRICE_CHART_FOR_COMPLAINT";
export const GET_PRICE_CHART_FOR_COMPLAINT_SUCCESS =
  "GET_PRICE_CHART_FOR_COMPLAINT_SUCCESS";
export const GET_PRICE_CHART_FOR_COMPLAINT_FAILURE =
  "GET_PRICE_CHART_FOR_COMPLAINT_FAILURE";

export const GET_SUMMARY_FOR_COMPLAINT = "GET_SUMMARY_FOR_COMPLAINT";
export const GET_SUMMARY_FOR_COMPLAINT_SUCCESS =
  "GET_SUMMARY_FOR_COMPLAINT_SUCCESS";
export const GET_SUMMARY_FOR_COMPLAINT_FAILURE =
  "GET_SUMMARY_FOR_COMPLAINT_FAILURE";

// NETWORKING

export const getComplaintList = (status = "pending", page = 1, limit = 250) => {
  return {
    types: [
      GET_COMPLAINT_LIST,
      GET_COMPLAINT_LIST_SUCCESS,
      GET_COMPLAINT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          status,
          page,
          limit,
        },
      },
    },
  };
};

export const getComplaint = (id) => {
  return {
    types: [GET_COMPLAINT, GET_COMPLAINT_SUCCESS, GET_COMPLAINT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createComplaint = (data) => {
  return {
    types: [
      CREATE_COMPLAINT,
      CREATE_COMPLAINT_SUCCESS,
      CREATE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateComplaint = (id, data) => {
  return {
    types: [
      UPDATE_COMPLAINT,
      UPDATE_COMPLAINT_SUCCESS,
      UPDATE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchComplaint = (id, data) => {
  return {
    types: [
      UPDATE_COMPLAINT,
      UPDATE_COMPLAINT_SUCCESS,
      UPDATE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteComplaint = (id) => {
  return {
    types: [
      DELETE_COMPLAINT,
      DELETE_COMPLAINT_SUCCESS,
      DELETE_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getComplaintProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PIECE_LIST_FOR_COMPLAINT,
      GET_PIECE_LIST_FOR_COMPLAINT_SUCCESS,
      GET_PIECE_LIST_FOR_COMPLAINT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetComplaintState = () => {
  return {
    type: RESET_COMPLAINT_STATE,
  };
};

export const toggleComplaintListDisplay = (display) => {
  return {
    type: TOGGLE_COMPLAINT_LIST_DISPLAY,
    display,
  };
};
