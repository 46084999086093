const url = "/api/v1/user_traits";

// Actions

export const RESET_USER_TRAIT_STATE = "RESET_USER_TRAIT_STATE";
export const TOGGLE_USER_TRAIT_LIST_DISPLAY = "TOGGLE_USER_TRAIT_LIST_DISPLAY";

export const GET_USER_TRAIT_LIST = "GET_USER_TRAIT_LIST";
export const GET_USER_TRAIT_LIST_SUCCESS = "GET_USER_TRAIT_LIST_SUCCESS";
export const GET_USER_TRAIT_LIST_FAILURE = "GET_USER_TRAIT_LIST_FAILURE";

export const GET_USER_TRAIT = "GET_USER_TRAIT";
export const GET_USER_TRAIT_SUCCESS = "GET_USER_TRAIT_SUCCESS";
export const GET_USER_TRAIT_FAILURE = "GET_USER_TRAIT_FAILURE";

export const CREATE_USER_TRAIT = "CREATE_USER_TRAIT";
export const CREATE_USER_TRAIT_SUCCESS = "CREATE_USER_TRAIT_SUCCESS";
export const CREATE_USER_TRAIT_FAILURE = "CREATE_USER_TRAIT_FAILURE";

export const UPDATE_USER_TRAIT = "UPDATE_USER_TRAIT";
export const UPDATE_USER_TRAIT_SUCCESS = "UPDATE_USER_TRAIT_SUCCESS";
export const UPDATE_USER_TRAIT_FAILURE = "UPDATE_USER_TRAIT_FAILURE";

export const DELETE_USER_TRAIT = "DELETE_USER_TRAIT";
export const DELETE_USER_TRAIT_SUCCESS = "DELETE_USER_TRAIT_SUCCESS";
export const DELETE_USER_TRAIT_FAILURE = "DELETE_USER_TRAIT_FAILURE";

// Networking

export const getUserTraitList = (
  page = 1,
  limit = 250,
  sort = "created_at",
  direction,
) => {
  return {
    types: [
      GET_USER_TRAIT_LIST,
      GET_USER_TRAIT_LIST_SUCCESS,
      GET_USER_TRAIT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getUserTrait = (id) => {
  return {
    types: [GET_USER_TRAIT, GET_USER_TRAIT_SUCCESS, GET_USER_TRAIT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createUserTrait = (data) => {
  return {
    types: [
      CREATE_USER_TRAIT,
      CREATE_USER_TRAIT_SUCCESS,
      CREATE_USER_TRAIT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateUserTrait = (id, data) => {
  return {
    types: [
      UPDATE_USER_TRAIT,
      UPDATE_USER_TRAIT_SUCCESS,
      UPDATE_USER_TRAIT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deleteUserTrait = (id) => {
  return {
    types: [
      UPDATE_USER_TRAIT,
      UPDATE_USER_TRAIT_SUCCESS,
      UPDATE_USER_TRAIT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const toggleUserTraitListDisplay = (display) => {
  return {
    type: TOGGLE_USER_TRAIT_LIST_DISPLAY,
    display,
  };
};

export const resetUserTrait = () => {
  return {
    type: RESET_USER_TRAIT_STATE,
  };
};
