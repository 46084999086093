const url = "/api/v1/posts";

// Actions

export const RESET_POST_STATE = "RESET_POST_STATE";
export const TOGGLE_POST_LIST_DISPLAY = "TOGGLE_POST_LIST_DISPLAY";

export const GET_POST_LIST = "GET_POST_LIST";
export const GET_POST_LIST_SUCCESS = "GET_POST_LIST_SUCCESS";
export const GET_POST_LIST_FAILURE = "GET_POST_LIST_FAILURE";

export const GET_POST = "GET_POST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAILURE = "GET_POST_FAILURE";

export const CREATE_POST = "CREATE_POST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";

export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const GET_POST_COMMENT_LIST = "GET_POST_COMMENT_LIST";
export const GET_POST_COMMENT_LIST_SUCCESS = "GET_POST_COMMENT_LIST_SUCCESS";
export const GET_POST_COMMENT_LIST_FAILURE = "GET_POST_COMMENT_LIST_FAILURE";

// Networking

export const getPostList = (
  page = 1,
  limit = 250,
  sort = "created_at",
  direction,
) => {
  return {
    types: [GET_POST_LIST, GET_POST_LIST_SUCCESS, GET_POST_LIST_FAILURE],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
          sort,
          direction,
        },
      },
    },
  };
};

export const getPost = (id) => {
  return {
    types: [GET_POST, GET_POST_SUCCESS, GET_POST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createPost = (data) => {
  return {
    types: [CREATE_POST, CREATE_POST_SUCCESS, CREATE_POST_FAILURE],
    payload: {
      request: {
        url,

        method: "POST",
        data,
      },
    },
  };
};

export const updatePost = (id, data) => {
  return {
    types: [UPDATE_POST, UPDATE_POST_SUCCESS, UPDATE_POST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",
        data,
      },
    },
  };
};

export const deletePost = (id) => {
  return {
    types: [UPDATE_POST, UPDATE_POST_SUCCESS, UPDATE_POST_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getPostCommentList = (id) => {
  return {
    types: [
      GET_POST_COMMENT_LIST,
      GET_POST_COMMENT_LIST_SUCCESS,
      GET_POST_COMMENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/comments`,
        method: "GET",
      },
    },
  };
};

export const togglePostListDisplay = (display) => {
  return {
    type: TOGGLE_POST_LIST_DISPLAY,
    display,
  };
};

export const resetPost = () => {
  return {
    type: RESET_POST_STATE,
  };
};
