import {
  CREATE_COMMENT,
  CREATE_COMMENT_FAILURE,
  CREATE_COMMENT_SUCCESS,
  GET_COMMENT,
  GET_COMMENT_FAILURE,
  GET_COMMENT_SUCCESS,
  RESET_COMMENT_STATE,
  UPDATE_COMMENT,
  UPDATE_COMMENT_FAILURE,
  UPDATE_COMMENT_SUCCESS,
  GET_COMMENT_COMMENT_LIST,
  GET_COMMENT_COMMENT_LIST_SUCCESS,
} from "../actions/comment";

import { Mixpanel } from "../../utils/Mixpanel";
import Post from "../../models/comment";

const INITIAL_STATE = {
  display: "table",

  isLoadingCommentList: true,
  hasMoreComments: true,

  isLoadingPost: true,
  isCreatingPost: false,
  isUpdatingPost: false,

  comment: null,
  comments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_COMMENT_STATE:
    case GET_COMMENT:
      return { ...INITIAL_STATE };
    case GET_COMMENT_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
        comment: new Post(action.payload.data),
      };
    case GET_COMMENT_FAILURE:
      return {
        ...state,
        isLoadingPost: false,
        error: action.error.response.data,
      };

    case CREATE_COMMENT:
      return {
        ...state,
        error: null,
        isCreatingPost: true,
      };
    case CREATE_COMMENT_SUCCESS: {
      Mixpanel.track("comment_created");
      return {
        ...state,
        isCreatingPost: false,
        comment: action.payload.data,
      };
    }
    case CREATE_COMMENT_FAILURE:
      return {
        ...state,
        isCreatingPost: false,
        error: action.error.response.data,
      };

    case UPDATE_COMMENT:
      return {
        ...state,
        isUpdatingPost: true,
      };
    case UPDATE_COMMENT_SUCCESS: {
      const comment = action.payload.data;
      Mixpanel.track("comment_updated", {
        category: comment.category,
        brand: comment.brand && comment.brand._id,
        gallery: comment.gallery && comment.gallery._id,
      });
      return {
        ...state,
        isUpdatingPost: false,
        comment: new Post(action.payload.data),
      };
    }
    case UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        isUpdatingPost: false,
        error: action.error.response.data,
      };

    case GET_COMMENT_COMMENT_LIST:
      return { ...state, isLoadingComments: true };
    case GET_COMMENT_COMMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoadingComments: false,
        comments: [...state.comments, ...action.payload.data],
        hasMoreComments: action.payload.data.length === 25,
      };
    }
    default:
      return state;
  }
};
