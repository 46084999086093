import {
  ADD_PRODUCT_TO_PRODUCT_ARTICLE_SUCCESS,
  CREATE_PRODUCT_ARTICLE,
  CREATE_PRODUCT_ARTICLE_FAILURE,
  CREATE_PRODUCT_ARTICLE_SUCCESS,
  DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_SUCCESS,
  DELETE_PRODUCT_ARTICLE,
  DELETE_PRODUCT_ARTICLE_FAILURE,
  DELETE_PRODUCT_ARTICLE_SUCCESS,
  GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE,
  GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_FAILURE,
  GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_SUCCESS,
  GET_PRODUCT_ARTICLE,
  GET_PRODUCT_ARTICLE_FAILURE,
  GET_PRODUCT_ARTICLE_SUCCESS,
  RESET_PRODUCT_ARTICLE_STATE,
  UPDATE_PRODUCT_ARTICLE,
  UPDATE_PRODUCT_ARTICLE_FAILURE,
  UPDATE_PRODUCT_ARTICLE_SUCCESS,
} from "../actions/product_article";

import { Mixpanel } from "../../utils/Mixpanel";
import ProductArticle from "../../models/product_article";

const INITIAL_STATE = {
  productArticle: null,

  products: [],

  isLoadingProductArticle: true,
  isCreatingProductArticle: false,
  isUpdatingProductArticle: false,
  isDeletingProductArticle: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PRODUCT_ARTICLE_STATE:
    case GET_PRODUCT_ARTICLE:
      return { ...INITIAL_STATE };
    case GET_PRODUCT_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoadingProductArticle: false,
        productArticle: new ProductArticle(action.payload.data),
        hasMoreProducts: true,
      };
    case GET_PRODUCT_ARTICLE_FAILURE:
      return {
        ...state,
        isLoadingProductArticle: false,
        error: action.error.response.data,
      };

    case GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE:
      return { ...state, isLoadingProducts: true };
    case GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    }
    case GET_PRODUCT_LIST_FOR_PRODUCT_ARTICLE_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };

    case CREATE_PRODUCT_ARTICLE:
      return {
        ...state,
        isCreatingProductArticle: true,
      };

    case CREATE_PRODUCT_ARTICLE_SUCCESS:
      Mixpanel.track("product_article_created");
      return {
        ...state,
        isCreatingProductArticle: false,
      };
    case CREATE_PRODUCT_ARTICLE_FAILURE:
      Mixpanel.track("product_article_created_failure");
      return {
        ...state,
        isCreatingProductArticle: false,
      };

    case UPDATE_PRODUCT_ARTICLE:
      return {
        ...state,
        isUpdatingProductArticle: true,
      };

    case UPDATE_PRODUCT_ARTICLE_SUCCESS:
      Mixpanel.track("product_article_updated");
      return {
        ...state,
        isUpdatingProductArticle: false,
        productArticle: new ProductArticle(action.payload.data),
      };
    case UPDATE_PRODUCT_ARTICLE_FAILURE:
      Mixpanel.track("product_article_updated_failure");
      return {
        ...state,
        isUpdatingProductArticle: false,
      };

    case DELETE_PRODUCT_ARTICLE:
      return {
        ...state,
        isDeletingProductArticle: true,
      };

    case DELETE_PRODUCT_ARTICLE_SUCCESS:
      Mixpanel.track("product_article_deleted");
      return {
        ...state,
        isDeletingProductArticle: false,
      };
    case DELETE_PRODUCT_ARTICLE_FAILURE:
      Mixpanel.track("product_article_deleted_failure");
      return {
        ...state,
        isDeletingProductArticle: false,
      };

    case ADD_PRODUCT_TO_PRODUCT_ARTICLE_SUCCESS:
    case DELETE_PRODUCT_FROM_PRODUCT_ARTICLE_SUCCESS: {
      return {
        ...state,
        isUpdatingProductArticle: false,
        productArticle: new ProductArticle(action.payload.data),
      };
    }

    default:
      return state;
  }
};
