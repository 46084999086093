import {
  GET_INVOICE,
  GET_INVOICE_FAILURE,
  GET_INVOICE_SUCCESS,
} from "../actions/invoice";
import Invoice from "../../models/invoice";

const INITIAL_STATE = {
  error: null,
  isLoadingInvoice: true,
  invoice: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE:
      return { ...INITIAL_STATE };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        isLoadingInvoice: false,
        invoice: new Invoice(action.payload.data),
      };
    case GET_INVOICE_FAILURE:
      return {
        ...state,
        isLoadingInvoice: false,
        error: action.error.response.data,
      };
    default:
      return state;
  }
};
