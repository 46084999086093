import Author from "./author";

class Article {
  constructor(data) {
    this._id = data._id || data.id;
    this.title = data.title || "";
    this.subtitle = data.subtitle || "";
    this.summary = data.summary || "";

    this.author = data.author && new Author(data.author);
    this.reviewer = data.reviewer && new Author(data.reviewer);

    this.created_at = data.created_at;

    this.category = data.category;

    this.image_url = data.image_url;
    this.small_image_url = data.small_image_url;
    this.medium_image_url = data.medium_image_url;

    this.sort_order = data.sort_order;
    this.is_premium = data.is_premium;

    this.type = data.type;
    this.vimeo_id = data.vimeo_id;

    this.products = data.products;

    this.about = data.about;

    this.action_type = data.action_type;
    this.action_text = data.action_text;
    this.action_url = data.action_url;

    this.video_url = data.video_url;
    this.mux_id = data.mux_id;
  }

  toFormData() {
    const formData = new FormData();
    formData.append("title", this.title);
    formData.append("subtitle", this.subtitle);
    formData.append("about", this.subtitle);
    formData.append("vimeo_id", this.vimeo_id);
    formData.append("category", this.category);
    return formData;
  }
}

export default Article;
