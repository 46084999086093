import { applyMiddleware, compose, createStore } from "redux";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";

import reducer from "./reducer";
import { auth } from "../utils/firebase";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async ({ getState, dispatch, getSourceAction }, req) => {
          if (auth.currentUser) {
            const token = await auth.currentUser.getIdToken();
            req.headers = {
              ...req.headers,
              Authorization: `Bearer ${token}`,
            };
          }
          return req;
        },
        error: ({ getState, dispatch, getSourceAction }, error) => {
          return error;
        },
      },
    ],
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, res) => {
          return Promise.resolve(res);
        },
        error: async ({ getState, dispatch, getSourceAction }, error) => {
          if (error.response && error.response.status) {
            switch (error.response.status) {
              case 401:
                try {
                  await auth.logout();
                } catch (e) {
                  console.error(e);
                }

                localStorage.clear();
                break;
              default:
                break;
            }
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(axiosMiddleware(client, middlewareConfig))),
);

export default store;
