const url = "/api/v1/advertisement_partners";

// Actions

export const TOGGLE_ADVERTISEMENT_PARTNER_LIST_DISPLAY =
  "TOGGLE_ADVERTISEMENT_PARTNER_LIST_DISPLAY";

export const RESET_ADVERTISEMENT_PARTNER_STATE =
  "RESET_ADVERTISEMENT_PARTNER_STATE";

export const GET_ADVERTISEMENT_PARTNER_LIST = "GET_ADVERTISEMENT_PARTNER_LIST";
export const GET_ADVERTISEMENT_PARTNER_LIST_SUCCESS =
  "GET_ADVERTISEMENT_PARTNER_LIST_SUCCESS";
export const GET_ADVERTISEMENT_PARTNER_LIST_FAILURE =
  "GET_ADVERTISEMENT_PARTNER_LIST_FAILURE";

export const GET_ADVERTISEMENT_PARTNER = "GET_ADVERTISEMENT_PARTNER";
export const GET_ADVERTISEMENT_PARTNER_SUCCESS =
  "GET_ADVERTISEMENT_PARTNER_SUCCESS";
export const GET_ADVERTISEMENT_PARTNER_FAILURE =
  "GET_ADVERTISEMENT_PARTNER_FAILURE";

export const CREATE_ADVERTISEMENT_PARTNER = "CREATE_ADVERTISEMENT_PARTNER";
export const CREATE_ADVERTISEMENT_PARTNER_SUCCESS =
  "CREATE_ADVERTISEMENT_PARTNER_SUCCESS";
export const CREATE_ADVERTISEMENT_PARTNER_FAILURE =
  "CREATE_ADVERTISEMENT_PARTNER_FAILURE";

export const UPDATE_ADVERTISEMENT_PARTNER = "UPDATE_ADVERTISEMENT_PARTNER";
export const UPDATE_ADVERTISEMENT_PARTNER_SUCCESS =
  "UPDATE_ADVERTISEMENT_PARTNER_SUCCESS";
export const UPDATE_ADVERTISEMENT_PARTNER_FAILURE =
  "UPDATE_ADVERTISEMENT_PARTNER_FAILURE";

export const DELETE_ADVERTISEMENT_PARTNER = "DELETE_ADVERTISEMENT_PARTNER";
export const DELETE_ADVERTISEMENT_PARTNER_SUCCESS =
  "DELETE_ADVERTISEMENT_PARTNER_SUCCESS";
export const DELETE_ADVERTISEMENT_PARTNER_FAILURE =
  "DELETE_ADVERTISEMENT_PARTNER_FAILURE";

export const GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER =
  "GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER";
export const GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS =
  "GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS";
export const GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE =
  "GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE";

export const GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER =
  "GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER";
export const GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS =
  "GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS";
export const GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE =
  "GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE";

// NETWORKING

export const getAdvertisementPartnerList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_ADVERTISEMENT_PARTNER_LIST,
      GET_ADVERTISEMENT_PARTNER_LIST_SUCCESS,
      GET_ADVERTISEMENT_PARTNER_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAdvertisementPartner = (id) => {
  return {
    types: [
      GET_ADVERTISEMENT_PARTNER,
      GET_ADVERTISEMENT_PARTNER_SUCCESS,
      GET_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAdvertisementPartner = (data) => {
  return {
    types: [
      CREATE_ADVERTISEMENT_PARTNER,
      CREATE_ADVERTISEMENT_PARTNER_SUCCESS,
      CREATE_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAdvertisementPartner = (id, data) => {
  return {
    types: [
      UPDATE_ADVERTISEMENT_PARTNER,
      UPDATE_ADVERTISEMENT_PARTNER_SUCCESS,
      UPDATE_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchAdvertisementPartner = (id, data) => {
  return {
    types: [
      UPDATE_ADVERTISEMENT_PARTNER,
      UPDATE_ADVERTISEMENT_PARTNER_SUCCESS,
      UPDATE_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteAdvertisementPartner = (id) => {
  return {
    types: [
      DELETE_ADVERTISEMENT_PARTNER,
      DELETE_ADVERTISEMENT_PARTNER_SUCCESS,
      DELETE_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getAdvertisementPartnerAdvertisementsList = (
  id,
  page = 1,
  limit = 25,
) => {
  return {
    types: [
      GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER,
      GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS,
      GET_ADVERTISEMENTS_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/advertisements`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getAdvertisementPartnerInvoicesList = (
  id,
  page = 1,
  limit = 25,
) => {
  return {
    types: [
      GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER,
      GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_SUCCESS,
      GET_INVOICES_LIST_FOR_ADVERTISEMENT_PARTNER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/invoices`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetAdvertisementPartnerState = () => {
  return {
    type: RESET_ADVERTISEMENT_PARTNER_STATE,
  };
};

export const toggleAdvertisementPartnerListDisplay = (display) => {
  return {
    type: TOGGLE_ADVERTISEMENT_PARTNER_LIST_DISPLAY,
    display,
  };
};
