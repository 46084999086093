const url = "/api/v1/ingredients";

// Actions

export const RESET_INGREDIENT_STATE = "RESET_INGREDIENT_STATE";

export const GET_INGREDIENT_LIST = "GET_INGREDIENT_LIST";
export const GET_INGREDIENT_LIST_SUCCESS = "GET_INGREDIENT_LIST_SUCCESS";
export const GET_INGREDIENT_LIST_FAILURE = "GET_INGREDIENT_LIST_FAILURE";

export const GET_INGREDIENT = "GET_INGREDIENT";
export const GET_INGREDIENT_SUCCESS = "GET_INGREDIENT_SUCCESS";
export const GET_INGREDIENT_FAILURE = "GET_INGREDIENT_FAILURE";

export const CREATE_INGREDIENT = "CREATE_INGREDIENT";
export const CREATE_INGREDIENT_SUCCESS = "CREATE_INGREDIENT_SUCCESS";
export const CREATE_INGREDIENT_FAILURE = "CREATE_INGREDIENT_FAILURE";

export const UPDATE_INGREDIENT = "UPDATE_INGREDIENT";
export const UPDATE_INGREDIENT_SUCCESS = "UPDATE_INGREDIENT_SUCCESS";
export const UPDATE_INGREDIENT_FAILURE = "UPDATE_INGREDIENT_FAILURE";

export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const DELETE_INGREDIENT_SUCCESS = "DELETE_INGREDIENT_SUCCESS";
export const DELETE_INGREDIENT_FAILURE = "DELETE_INGREDIENT_FAILURE";

export const GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT =
  "GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT";
export const GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT_SUCCESS =
  "GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT_SUCCESS";
export const GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT_FAILURE =
  "GET_AUCTION_RECORDS_LIST_FOR_INGREDIENT_FAILURE";

export const GET_ARTWORKS_LIST_FOR_INGREDIENT =
  "GET_ARTWORKS_LIST_FOR_INGREDIENT";
export const GET_ARTWORKS_LIST_FOR_INGREDIENT_SUCCESS =
  "GET_ARTWORKS_LIST_FOR_INGREDIENT_SUCCESS";
export const GET_ARTWORKS_LIST_FOR_INGREDIENT_FAILURE =
  "GET_ARTWORKS_LIST_FOR_INGREDIENT_FAILURE";

export const GET_PRODUCT_LIST_FOR_INGREDIENT =
  "GET_PRODUCT_LIST_FOR_INGREDIENT";
export const GET_PRODUCT_LIST_FOR_INGREDIENT_SUCCESS =
  "GET_PRODUCT_LIST_FOR_INGREDIENT_SUCCESS";
export const GET_PRODUCT_LIST_FOR_INGREDIENT_FAILURE =
  "GET_PRODUCT_LIST_FOR_INGREDIENT_FAILURE";

export const GET_EXHIBITION_LIST_FOR_INGREDIENT =
  "GET_EXHIBITION_LIST_FOR_INGREDIENT";
export const GET_EXHIBITION_LIST_FOR_INGREDIENT_SUCCESS =
  "GET_EXHIBITION_LIST_FOR_INGREDIENT_SUCCESS";
export const GET_EXHIBITION_LIST_FOR_INGREDIENT_FAILURE =
  "GET_EXHIBITION_LIST_FOR_INGREDIENT_FAILURE";

export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT_SUCCESS =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT_SUCCESS";
export const GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT_FAILURE =
  "GET_NUMBER_OF_ARTWORKS_SOLD_FOR_INGREDIENT_FAILURE";

export const GET_SELL_THROUGH_RATES_FOR_INGREDIENT =
  "GET_SELL_THROUGH_RATES_FOR_INGREDIENT";
export const GET_SELL_THROUGH_RATES_FOR_INGREDIENT_SUCCESS =
  "GET_SELL_THROUGH_RATES_FOR_INGREDIENT_SUCCESS";
export const GET_SELL_THROUGH_RATES_FOR_INGREDIENT_FAILURE =
  "GET_SELL_THROUGH_RATES_FOR_INGREDIENT_FAILURE";

export const TOGGLE_INGREDIENT_LIST_DISPLAY = "TOGGLE_INGREDIENT_LIST_DISPLAY";

export const GET_SLPAE_FOR_INGREDIENT = "GET_SLPAE_FOR_INGREDIENT";
export const GET_SLPAE_FOR_INGREDIENT_SUCCESS =
  "GET_SLPAE_FOR_INGREDIENT_SUCCESS";
export const GET_SLPAE_FOR_INGREDIENT_FAILURE =
  "GET_SLPAE_FOR_INGREDIENT_FAILURE";

export const GET_PRICE_CHART_FOR_INGREDIENT = "GET_PRICE_CHART_FOR_INGREDIENT";
export const GET_PRICE_CHART_FOR_INGREDIENT_SUCCESS =
  "GET_PRICE_CHART_FOR_INGREDIENT_SUCCESS";
export const GET_PRICE_CHART_FOR_INGREDIENT_FAILURE =
  "GET_PRICE_CHART_FOR_INGREDIENT_FAILURE";

export const GET_SUMMARY_FOR_INGREDIENT = "GET_SUMMARY_FOR_INGREDIENT";
export const GET_SUMMARY_FOR_INGREDIENT_SUCCESS =
  "GET_SUMMARY_FOR_INGREDIENT_SUCCESS";
export const GET_SUMMARY_FOR_INGREDIENT_FAILURE =
  "GET_SUMMARY_FOR_INGREDIENT_FAILURE";

// NETWORKING

export const getIngredientList = (page = 1, limit = 250) => {
  return {
    types: [
      GET_INGREDIENT_LIST,
      GET_INGREDIENT_LIST_SUCCESS,
      GET_INGREDIENT_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const getIngredient = (id) => {
  return {
    types: [GET_INGREDIENT, GET_INGREDIENT_SUCCESS, GET_INGREDIENT_FAILURE],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createIngredient = (data) => {
  return {
    types: [
      CREATE_INGREDIENT,
      CREATE_INGREDIENT_SUCCESS,
      CREATE_INGREDIENT_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",

        data,
      },
    },
  };
};

export const updateIngredient = (id, data) => {
  return {
    types: [
      UPDATE_INGREDIENT,
      UPDATE_INGREDIENT_SUCCESS,
      UPDATE_INGREDIENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const patchIngredient = (id, data) => {
  return {
    types: [
      UPDATE_INGREDIENT,
      UPDATE_INGREDIENT_SUCCESS,
      UPDATE_INGREDIENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PATCH",

        data,
      },
    },
  };
};

export const deleteIngredient = (id) => {
  return {
    types: [
      DELETE_INGREDIENT,
      DELETE_INGREDIENT_SUCCESS,
      DELETE_INGREDIENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getIngredientProductsList = (id, page = 1, limit = 25) => {
  return {
    types: [
      GET_PRODUCT_LIST_FOR_INGREDIENT,
      GET_PRODUCT_LIST_FOR_INGREDIENT_SUCCESS,
      GET_PRODUCT_LIST_FOR_INGREDIENT_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}/products`,

        params: {
          page,
          limit,
        },
      },
    },
  };
};

export const resetIngredientState = () => {
  return {
    type: RESET_INGREDIENT_STATE,
  };
};

export const toggleIngredientListDisplay = (display) => {
  return {
    type: TOGGLE_INGREDIENT_LIST_DISPLAY,
    display,
  };
};
