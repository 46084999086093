import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import "firebase/compat/performance";
import "firebase/compat/app-check";

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const storage = firebase.storage();
export const performance = firebase.performance();
export const messaging = firebase.messaging();
export const FieldValue = firebase.firestore.FieldValue;
