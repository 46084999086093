import React from "react";
import { Route, Switch } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";

import LoginRoute from "./Login";
import NotFoundRoute from "./NotFound";
import Dashboard from "./Dashboard";
import BrandRoute from "./Brands";
import ProductRoute from "./Products";
import AccountRoute from "./Account";
import PasswordRecoverRoute from "./PasswordRecover";
import PasswordResetRoute from "./PasswordReset";
import ArticlesRoute from "./Articles";
import UsersRoute from "./Users";
import IngredientsRoute from "./Ingredients";
import ProductRecommendationsRoute from "./ProductRecommendations";
import ComplaintsRoute from "./Complaints";
import FeedsRoute from "./Feeds";
import FeedPostsRoute from "./FeedPosts";
import ArticleSectionsRoute from "./ArticleSections";
import ArticleCollectionsRoute from "./ArticleCollections";
import DeduplicateProductsRoute from "./DeduplicateProducts";
import ProductsInNeedOfImagesRoute from "./ProductsInNeedOfImages";
import UsersInNeedOfRecommendationsRoute from "./UsersInNeedOfRecommendations";
import AuthorsRoute from "./Authors";
import AdvertisementsRoute from "./Advertisements";
import AdvertisementPartnersRoute from "./AdvertisementPartners";
import ProductArticlesRoute from "./ProductArticles";
import PostsRoute from "./Posts";
import InvoicesRoute from "./Invoices";
import UserTraits from "./UserTraits";
import Comments from "./Comments";
import NotAdminRoute from "./NotAdmin";

export default function createRoutes(store) {
  return (
    <CoreLayout>
      <Switch>
        <Route
          exact
          path={Dashboard.path}
          component={() => <Dashboard.component />}
        />
        {[
          LoginRoute,
          BrandRoute,
          ProductRoute,
          AccountRoute,
          PasswordRecoverRoute,
          PasswordResetRoute,
          ArticlesRoute,
          UsersRoute,
          IngredientsRoute,
          ProductRecommendationsRoute,
          ComplaintsRoute,
          FeedPostsRoute,
          FeedsRoute,
          ArticleSectionsRoute,
          ArticleSectionsRoute,
          ArticleCollectionsRoute,
          DeduplicateProductsRoute,
          ProductsInNeedOfImagesRoute,
          UsersInNeedOfRecommendationsRoute,
          AuthorsRoute,
          AdvertisementsRoute,
          AdvertisementPartnersRoute,
          ProductArticlesRoute,
          PostsRoute,
          InvoicesRoute,
          UserTraits,
          Comments,
          NotAdminRoute,
        ].map((settings, index) => (
          <Route key={`Route-${index}`} {...settings} />
        ))}
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
  );
}
