const url = "/api/v1/me";

// Actions

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS";
export const UPDATE_CURRENT_USER_FAILURE = "UPDATE_CURRENT_USER_FAILURE";

export const UPDATE_CURRENT_USER_PROFESSIONAL =
  "UPDATE_CURRENT_USER_PROFESSIONAL";
export const UPDATE_CURRENT_USER_PROFESSIONAL_SUCCESS =
  "UPDATE_CURRENT_USER_PROFESSIONAL_SUCCESS";
export const UPDATE_CURRENT_USER_PROFESSIONAL_FAILURE =
  "UPDATE_CURRENT_USER_PROFESSIONAL_FAILURE";

export const UPDATE_CURRENT_USER_ARTIST = "UPDATE_CURRENT_USER_ARTIST";
export const UPDATE_CURRENT_USER_ARTIST_SUCCESS =
  "UPDATE_CURRENT_USER_ARTIST_SUCCESS";
export const UPDATE_CURRENT_USER_ARTIST_FAILURE =
  "UPDATE_CURRENT_USER_ARTIST_FAILURE";

export const UPDATE_CURRENT_USER_GALLERY = "UPDATE_CURRENT_USER_GALLERY";
export const UPDATE_CURRENT_USER_GALLERY_SUCCESS =
  "UPDATE_CURRENT_USER_GALLERY_SUCCESS";
export const UPDATE_CURRENT_USER_GALLERY_FAILURE =
  "UPDATE_CURRENT_USER_GALLERY_FAILURE";

export const UPDATE_CURRENT_USER_PASSWORD = "UPDATE_CURRENT_USER_PASSWORD";
export const UPDATE_CURRENT_USER_PASSWORD_SUCCESS =
  "UPDATE_CURRENT_USER_PASSWORD_SUCCESS";
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE =
  "UPDATE_CURRENT_USER_PASSWORD_FAILURE";

export const GET_CURRENT_USER_ARTIST_LIST = "GET_CURRENT_USER_ARTIST_LIST";
export const GET_CURRENT_USER_ARTIST_LIST_SUCCESS =
  "GET_CURRENT_USER_ARTIST_LIST_SUCCESS";
export const GET_CURRENT_USER_ARTIST_LIST_FAILURE =
  "GET_CURRENT_USER_ARTIST_LIST_FAILURE";

export const ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST =
  "ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST";
export const ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_SUCCESS =
  "ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_SUCCESS";
export const ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_FAILURE =
  "ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_FAILURE";

export const DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST =
  "DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST";
export const DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_SUCCESS =
  "DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_SUCCESS";
export const DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_FAILURE =
  "DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_FAILURE";

export const GET_CURRENT_USER_PROFESSIONAL_LIST =
  "GET_CURRENT_USER_PROFESSIONAL_LIST";
export const GET_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS =
  "GET_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS";
export const GET_CURRENT_USER_PROFESSIONAL_LIST_FAILURE =
  "GET_CURRENT_USER_PROFESSIONAL_LIST_FAILURE";

export const ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST =
  "ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST";
export const ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS =
  "ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS";
export const ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_FAILURE =
  "ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_FAILURE";

export const DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST =
  "DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST";
export const DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS =
  "DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS";
export const DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_FAILURE =
  "DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_FAILURE";

export const GET_CURRENT_USER_PROFESSIONAL = "GET_CURRENT_USER_PROFESSIONAL";
export const GET_CURRENT_USER_PROFESSIONAL_SUCCESS =
  "GET_CURRENT_USER_PROFESSIONAL_SUCCESS";
export const GET_CURRENT_USER_PROFESSIONAL_FAILURE =
  "GET_CURRENT_USER_PROFESSIONAL_FAILURE";

export const GET_CURRENT_USER_PROFESSIONAL_CLIENTS =
  "GET_CURRENT_USER_PROFESSIONAL_CLIENTS";
export const GET_CURRENT_USER_PROFESSIONAL_CLIENTS_SUCCESS =
  "GET_CURRENT_USER_PROFESSIONAL_CLIENTS_SUCCESS";
export const GET_CURRENT_USER_PROFESSIONAL_CLIENTS_FAILURE =
  "GET_CURRENT_USER_PROFESSIONAL_CLIENTS_FAILURE";

export const DELETE_ME_DATA = "DELETE_ME_DATA";
export const DELETE_ME_DATA_SUCCESS = "DELETE_ME_DATA_SUCCESS";
export const DELETE_ME_DATA_FAILURE = "DELETE_ME_DATA_FAILURE";

export const GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK =
  "GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK";
export const GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_SUCCESS =
  "GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_SUCCESS";
export const GGET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_FAILURE =
  "GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_FAILURE";

// Networking

export const getCurrentUser = () => {
  return {
    types: [
      GET_CURRENT_USER,
      GET_CURRENT_USER_SUCCESS,
      GET_CURRENT_USER_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
};

export const updateCurrentUser = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER,
      UPDATE_CURRENT_USER_SUCCESS,
      UPDATE_CURRENT_USER_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateCurrentUserPassword = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER,
      UPDATE_CURRENT_USER_SUCCESS,
      UPDATE_CURRENT_USER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/password`,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateCurrentUserPayment = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER,
      UPDATE_CURRENT_USER_SUCCESS,
      UPDATE_CURRENT_USER_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/password`,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateProfessional = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER_PROFESSIONAL,
      UPDATE_CURRENT_USER_PROFESSIONAL_SUCCESS,
      UPDATE_CURRENT_USER_PROFESSIONAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professional`,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateGallery = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER_GALLERY,
      UPDATE_CURRENT_USER_GALLERY_SUCCESS,
      UPDATE_CURRENT_USER_GALLERY_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/gallery`,
        method: "PUT",
        data,
      },
    },
  };
};

export const updateBrand = (data) => {
  return {
    types: [
      UPDATE_CURRENT_USER_ARTIST,
      UPDATE_CURRENT_USER_ARTIST_SUCCESS,
      UPDATE_CURRENT_USER_ARTIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/brand`,
        method: "PUT",
        data,
      },
    },
  };
};

export const getCurrentUserBrandList = () => {
  return {
    types: [
      GET_CURRENT_USER_ARTIST_LIST,
      GET_CURRENT_USER_ARTIST_LIST_SUCCESS,
      GET_CURRENT_USER_ARTIST_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/brands`,
      },
    },
  };
};

export const addBrandToCurrentUserBrandList = (data) => {
  return {
    types: [
      ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST,
      ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_SUCCESS,
      ADD_ARTIST_TO_CURRENT_USER_ARTIST_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/brands`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteBrandFromCurrentUserBrandList = (id) => {
  return {
    types: [
      DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST,
      DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_SUCCESS,
      DELETE_ARTIST_FROM_CURRENT_USER_ARTIST_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/brands/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getCurrentUserProfessionalList = () => {
  return {
    types: [
      GET_CURRENT_USER_PROFESSIONAL_LIST,
      GET_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS,
      GET_CURRENT_USER_PROFESSIONAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professionals`,
      },
    },
  };
};

export const addProfessionalToCurrentUserProfessionalList = (data) => {
  return {
    types: [
      ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST,
      ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS,
      ADD_PROFESSIONAL_TO_CURRENT_USER_PROFESSIONAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professionals`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteProfessionalFromCurrentUserProfessionalList = (id) => {
  return {
    types: [
      DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST,
      DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_SUCCESS,
      DELETE_PROFESSIONAL_FROM_CURRENT_USER_PROFESSIONAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professionals/${id}`,
        method: "DELETE",
      },
    },
  };
};

export const getCurrentUserProfessional = () => {
  return {
    types: [
      GET_CURRENT_USER_PROFESSIONAL,
      GET_CURRENT_USER_PROFESSIONAL_SUCCESS,
      GET_CURRENT_USER_PROFESSIONAL_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professional`,
      },
    },
  };
};

export const getCurrentUserProfessionalClients = () => {
  return {
    types: [
      GET_CURRENT_USER_PROFESSIONAL_CLIENTS,
      GET_CURRENT_USER_PROFESSIONAL_CLIENTS_SUCCESS,
      GET_CURRENT_USER_PROFESSIONAL_CLIENTS_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professional/users`,
      },
    },
  };
};

export const getCurrentUserProfessionalAccountLink = () => {
  return {
    types: [
      GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK,
      GET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_SUCCESS,
      GGET_CURRENT_USER_PROFESSIONAL_ACCOUNT_LINK_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/professional/account_link`,
      },
    },
  };
};

// DANGER ZONE

export const deleteMeData = (data) => {
  return {
    types: [DELETE_ME_DATA, DELETE_ME_DATA_SUCCESS, DELETE_ME_DATA_FAILURE],
    payload: {
      request: {
        url: `${url}/data`,
        method: "DELETE",
        data,
      },
    },
  };
};
