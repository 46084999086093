import {
  GET_FEED_POST,
  GET_FEED_POST_FAILURE,
  GET_FEED_POST_LIST,
  GET_FEED_POST_LIST_FAILURE,
  GET_FEED_POST_LIST_SUCCESS,
  GET_FEED_POST_SUCCESS,
  GET_PIECE_LIST_FOR_FEED_POST,
  GET_PIECE_LIST_FOR_FEED_POST_FAILURE,
  GET_PIECE_LIST_FOR_FEED_POST_SUCCESS,
} from "../actions/feed_post";

const INITIAL_STATE = {
  isLoadingFeedList: true,
  feed_posts: [],
  hasMoreFeeds: true,

  isLoadingProducts: true,
  products: [],
  hasMoreProducts: true,

  error: null,

  isLoadingFeed: true,
  feed_post: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FEED_POST_LIST:
      if (action.payload.request && action.payload.request.params.page <= 1) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
      break;
    case GET_FEED_POST_LIST_SUCCESS:
      return {
        ...state,
        isLoadingFeedList: false,
        feed_posts: [...state.feed_posts, ...action.payload.data],
        hasMoreFeeds: action.payload.data.length === 25,
      };
      break;
    case GET_FEED_POST_LIST_FAILURE:
      return {
        ...state,
        isLoadingFeedList: false,
        error: action.error.response.data,
        hasMoreFeeds: false,
      };

    case GET_FEED_POST:
      return { ...INITIAL_STATE };
    case GET_FEED_POST_SUCCESS:
      return {
        ...state,
        isLoadingFeed: false,
        feed_post: action.payload.data,
        hasMoreAuctionRecords: true,
        hasMoreAuctionProducts: true,
      };
    case GET_FEED_POST_FAILURE:
      return {
        ...state,
        isLoadingFeed: false,
        error: action.error.response.data,
      };

    case GET_PIECE_LIST_FOR_FEED_POST:
      return { ...state };
    case GET_PIECE_LIST_FOR_FEED_POST_SUCCESS:
      return {
        ...state,
        isLoadingProducts: false,
        products: [...state.products, ...action.payload.data],
        hasMoreProducts: action.payload.data.length === 25,
      };
    case GET_PIECE_LIST_FOR_FEED_POST_FAILURE:
      return {
        ...state,
        isLoadingProducts: false,
        error: action.error.response.data,
        hasMoreProducts: false,
      };
    default:
      return state;
  }
};
