import { fromJS, List, Map } from "immutable";

import {
  CREATE_USER_TRAIT_SUCCESS,
  GET_USER_TRAIT_LIST,
  GET_USER_TRAIT_LIST_FAILURE,
  GET_USER_TRAIT_LIST_SUCCESS,
  UPDATE_USER_TRAIT_SUCCESS,
} from "../actions/user_trait";

const INITIAL_STATE = Map({
  display: "table",
  isLoadingUserTraitList: true,
  user_traits: List(),
  hasMoreUserTraits: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_TRAIT_LIST:
      return state
        .set("isLoadingUserTraitList", true)
        .set(
          "user_traits",
          action.payload.request.params.page <= 1
            ? List()
            : state.get("user_traits"),
        );
    case GET_USER_TRAIT_LIST_SUCCESS:
      return state
        .set("isLoadingUserTraitList", false)
        .set("hasMoreUserTraits", false)
        .update("user_traits", (arr) =>
          arr.concat(fromJS((action.payload.data || []).filter(Boolean))),
        );
    case GET_USER_TRAIT_LIST_FAILURE:
      return state
        .set("isLoadingUserTraitList", false)
        .set("hasMoreUserTraits", false)
        .set("error", fromJS(action.error.response.data));
    case CREATE_USER_TRAIT_SUCCESS:
      return state.update("user_traits", (arr) =>
        arr.push(fromJS(action.payload.data)),
      );
    case UPDATE_USER_TRAIT_SUCCESS: {
      const index = state
        .get("user_traits")
        .findIndex((item) => item.get("_id") === action.payload.data._id);
      return state.setIn(["user_traits", index], fromJS(action.payload.data));
    }
    default:
      return state;
  }
};
